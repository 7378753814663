import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileImageComponent } from './profile-image.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NameModule } from '../name/name.module';
import { PictureModule } from '../picture/picture.module';

@NgModule({
  declarations: [
    ProfileImageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NameModule,
    PictureModule
  ],
  exports: [
    ProfileImageComponent
  ]
})
export class ProfileImageModule { }
