<div class="empty">
    <img class="image" [src]="src" alt="no result found" />
    <div class="info">
        <div class="no-result">{{ title ? title : ('ProfilePage.NoProduct' | translate) }}</div>
        <p class="short-desc">{{shortDescription}}</p>
    </div>
    <div *ngIf="hasBtn">
        <button type="button" role="button" mat-flat-button color="primary" class="btn-md-1"
            (click)="createBtnEmitter.emit()">
            <mat-icon class="svg-path" svgIcon="icon-add--product"></mat-icon>
            {{btnName}}
        </button>
    </div>
</div>