import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ButtonSendMessageComponent } from './components/button-send-message/button-send-message.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ButtonSendMessageComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    RouterModule
  ],
  exports: [ButtonSendMessageComponent],
})
export class ButtonSendMessageModule { }
