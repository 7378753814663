<div [ngSwitch]="pageSection">
  <div *ngSwitchCase="PageSectionEnum.CREATE_PRODUCT">
    <div mat-dialog-title class="dialog-header" *ngIf="createType === CREATE_TYPE.BUY_LISTING">
      <h3 class="mat-h3">
        {{
        (isEdit ? "EditAnnouncement" : "PostAnnouncement" ) | translate
        }}
      </h3>
      <button class="close-x" mat-icon-button (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="createType === CREATE_TYPE.PRODUCT" mat-dialog-title class="dialog-header">
      <h3 class="mat-h3">
        {{ (isEdit ? "EditPostProduct" : "PostProduct" ) | translate }}
      </h3>
      <button class="close-x" mat-icon-button (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <mat-dialog-content>
      <form class="dialog-wrapper" [formGroup]="form" id="form-caption" autocomplete="off" (ngSubmit)="onSubmit()">
        <div class="flex-row" *ngIf="createType === CREATE_TYPE.BUY_LISTING">
          <app-profile-image [profileImage]="profile?.photo_url | token: 'assets/imgs/img-no-gallery.svg'"
            [fullName]="profile | fullName : ''" className="font-bold">
          </app-profile-image>
        </div>
        <div class="flex-row" *ngIf="createType === CREATE_TYPE.PRODUCT && product?.shop?._id">
          <app-profile-image [profileImage]="product?.shop?.photo_url | token: 'assets/imgs/img-no-gallery.svg'"
            [fullName]="product?.shop?.name | titlecase" className="font-bold">
          </app-profile-image>
        </div>
        <div class="flex-row">
          <textarea type="text" matInput placeholder="{{ 'ProductPage.WriteDescription' | translate }}"
            formControlName="description">
            </textarea>
        </div>
        <div class="flex-row mt">
          <div class="input-column">
            <label>{{ "ProfilePage.ProductName" | translate }}
              <span class="required"></span>
            </label>
            <div class="input-group input-text">
              <input type="text" formControlName="title" maxlength="151"/>
            </div>
            <mat-error *ngIf="form.get('title').errors?.maxlength">
              {{ 'ValidationErr.InvalidTitleLength' | translate }}
            </mat-error>
          </div>
          <div class="input-column">
            <label>{{ "sellProductDetailPage.Quantity" | translate }}
              <span class="required"></span>
            </label>
            <div class="input-group with-select">
              <input type="number" min="0" formControlName="qty" />
              <mat-select class="custom-select" formControlName="unit">
                <mat-option *ngFor="let item of units" [value]="item._id">{{
                  item?.symbol
                  }}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="input-column">
            <label>{{ "FromPrice" | translate }}
              <span class="required"></span>
            </label>
            <div class="input-group with-select">
              <input type="number" [min]="0" formControlName="from_price" />
              <mat-select class="custom-select" formControlName="currency" (selectionChange)="onCurrencyChange($event)">
                <mat-option *ngFor="let item of currencies" [value]="item._id">{{
                  item?.symbol
                  }}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="input-column">
            <label>{{ "ToPrice" | translate }} </label>
            <div class="input-group with-select">
              <input type="number" [min]="0" formControlName="to_price" />
              <mat-select class="custom-select" formControlName="currency" (selectionChange)="onCurrencyChange($event)">
                <mat-option *ngFor="let item of currencies" [value]="item._id">{{
                  item?.symbol
                  }}</mat-option>
              </mat-select>
            </div>
            <div class="error-toPrice"
              *ngIf="form.get('to_price')?.touched && form.get('to_price').errors?.invalidPrice">
              {{ "validate_msg.error.max" | translate : { bigger: ("price_to" | translate), smaller: ("Price" |
              translate)} }}
            </div>
          </div>
        </div>
        <div class="flex-row mt">
          <div class="input-column">
            <label>{{ "sellProductDetailPage.Type" | translate }}
              <span class="required"></span>
            </label>
            <!-- this input hidden just for validation, because i'm fail to past control category to child and validate -->
            <input type="hidden" formControlName="categories" class="hidden" />
            <app-multiselect-autocomplete *ngIf="categories?.length" [data]="categories"
              [preSelected]="form.value.categories" (result)="selectChange($event)"></app-multiselect-autocomplete>
          </div>
        </div>
        <h4 class="mat-h4 mt">
          {{ "PostCaptionPage.SelectPicture" | translate }}<span class="required"></span>
        </h4>
        <input type="file" id="imageInput" (change)="onFilePicked($event)" #uploader style="display: none"
          accept="image/png,image/gif,image/jpeg,image/jpg,image/bmp" multiple />
        <div class="img-container">
          <div class="img-inset" *ngFor="let img of images; let i = index">
            <img class="img" [src]="img.isNew ? img.base64 : img.url" [alt]="'img' + i"
              onerror="this.src='assets/imgs/img-no-gallery.svg'" />
            <div class="blur"></div>
            <div class="edit">
              <button type="button" mat-flat-button class="btn-sm"
                (click)="onPageSectionChanged(PageSectionEnum.EDIT_IMAGE, i)">
                <mat-icon svgIcon="icon-edit" class="svg-path svg-stroke color-black"></mat-icon>
                {{"ActionBtn.Edit" | translate}}
              </button>
            </div>
            <div class="remove">
              <button type="button" mat-icon-button (click)="removeFile(i)">
                <mat-icon svgIcon="icon-close"></mat-icon>
              </button>
            </div>
          </div>
          <div class="img-inset add-pic" (click)="pickFile()">
            <div class="inside-icon">
              <img src="assets/imgs/icon-add-picture.svg" alt="Image gray picture" />
              <span class="caption-md">{{ "AddPicture" | translate }}</span>
            </div>
          </div>
        </div>
        <h4 class="mat-h4 mt desc">
          {{ "ProductPage.AdditionalInfo" | translate }}
          <span class="required"></span> <span> ({{ 'Sign' | translate }} </span>
          <span class="required"></span> <span> {{ 'RequiredNoted' | translate }})</span>
        </h4>
        <div class="flex-row" *ngIf="createType === CREATE_TYPE.BUY_LISTING">
          <div class="input-column select-category">
            <label>{{ "Address.Province" | translate }}
              <span class="required"></span>
            </label>
            <div class="input-group">
              <mat-select name="province" formControlName="province">
                <mat-option *ngFor="let item of provinces" [value]="item._id">{{
                  item?.name
                  }}</mat-option>
              </mat-select>
              <mat-icon svgIcon="icon-locate"></mat-icon>
            </div>
          </div>
          <ng-container>
            <div class="input-column">
              <label>{{ "sellProductDetailPage.Address" | translate }}</label>
              <div class="input-group input-text">
                <input type="text" formControlName="address" maxlength="101" />
              </div>
              <mat-error *ngIf="form.get('address').errors?.maxlength">
                {{ 'ValidationErr.InvalidAddressLength' | translate }}
              </mat-error>
            </div>
            <div class="input-column">
              <label>{{ "ContactNumber" | translate }}</label>
              <div class="input-group input-text for-chip-list">
                <mat-chip-list #chipList>
                  <mat-chip *ngFor="let tel of tels" (removed)="remove(tel)">
                    {{ tel }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input type="number" [matChipInputFor]="chipList" formControlName="tels"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)" />
                </mat-chip-list>
              </div>
              <mat-error *ngIf="form.get('tels').errors?.pattern">
                {{ 'ProfilePage.contactFormat' | translate }}
              </mat-error>
            </div>
          </ng-container>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="mat-dialog-actions" align="end">
      <button [disabled]="isLoading || form.invalid || (images.length <= 0)" form="form-caption" type="submit"
        mat-flat-button color="primary" class="btn-md">
        <mat-icon class="svg-path svg-fill" svgIcon="icon-post-normal"></mat-icon>
        {{
        ( isEdit ? "ActionBtn.Edit" : "ActionBtn.Post" ) | translate
        }}
      </button>
    </mat-dialog-actions>
  </div>
  <div *ngSwitchCase="PageSectionEnum.EDIT_IMAGE">
    <div class="dialog-header">
      <div class="act-left" (click)="onPageSectionChanged(PageSectionEnum.CREATE_PRODUCT)">
        <mat-icon matRipple svgIcon="icon-back"></mat-icon>
        <!-- កែប្រែរូបភាព -->
        <h3 class="mat-h3" mat-dialog-title>
          កែប្រែរូបភាព
        </h3>
      </div>

    </div>
    <mat-divider></mat-divider>
    <app-image-alteration [imageFile]="editImageFile" [imageURL]="imageURL" (onImageSaved)="onEditImageSaved($event)"
      (onCanceled)="onCancelImageClick($event)"></app-image-alteration>
  </div>
  <div *ngSwitchDefault>Empty Page Request</div>
</div>