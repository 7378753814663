import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/app/helpers/must-match';
import { RequestErrorEnum } from 'src/app/models/enums/request-error.enum';
import { ConfirmSuccessComponent } from 'src/app/routes/register/components/confirm-success/confirm-success.component';
import { AuthService } from 'src/app/services/auth.service';
import { ForgetPasswordService } from 'src/app/services/forget-password.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  form: FormGroup;
  hide: boolean = true;
  confirmPassword: boolean = true;
  invalidCode = false;
  email: string = '';
  token: string = '';
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private snackBarService: SnackbarService,
    private forgetpasswordService: ForgetPasswordService,
    public authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        password: [null, [Validators.required, Validators.minLength(8)]],
        confirm_password: [null],
      },
      {
        validators: MustMatch('password', 'confirm_password'),
      }
    );
    this.email = this.route.snapshot.queryParams?.email;
    this.token = this.route.snapshot.queryParams?.token;
  }

  onSubmit(formDirective: FormGroupDirective) {
    if (this.form.valid) {
      const formValue = this.form.value;
      const data = {
        password: formValue.password,
        email: this.email,
        token: this.token,
      };
      this.forgetpasswordService.changeNewPassword({ data }).subscribe(
        (res) => {
          this.openDialogSuccess();
          setTimeout(() => {
            this.onCloseDialog();
          }, 2000);
        },
        (err) => {
          this.invalidCode = true;
          if (err && err?.error_type === RequestErrorEnum[404]) {
            this.snackBarService.onShowSnackbar(
              'Status.EmailDoesNotExist',
              false
            );
          } else {
            this.snackBarService.onShowSnackbar('Status.SomethingWrong', false);
          }
        }
      );
    }
  }

  openDialogSuccess() {
    this.dialog.open(ConfirmSuccessComponent, {
      panelClass: 'medium-modal',
      data: {},
    });
  }

  onCloseDialog() {
    this.dialog.closeAll();
    this.router.navigate(['login']);
  }
}
