<div class="main-layout bs-container bs-container-lg bs-container-md bs-container-sm">
  <app-header [searchLayout]="true" (onSearchEmitter)="onSearch($event)" [keyword]="keyword"
    (onMenuClicked)="menuClickHandler()"></app-header>
  <div class="wrapper">
    <mat-sidenav-container>
      <mat-sidenav #drawer [(opened)]="isExpanded" [mode]="navbarMode">
        <div class="side-menu-wrapper">
          <app-search-side-menu [keyword]="keyword"></app-search-side-menu>
        </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="content-wrapper">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>