import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-search-layout',
  templateUrl: './search-layout.component.html',
  styleUrls: ['./search-layout.component.scss']
})
export class SearchLayoutComponent implements OnInit {

  isExpanded = true;
  navbarMode: MatDrawerMode = 'side';
  keyword: string = '';

  @ViewChild('drawer') drawer: MatSidenav;

  @HostListener('window:resize', ['$event'])

  isHandset$: Observable<boolean> = this.observer.observe(Breakpoints.Handset)
    .pipe(
      map(result => { return result.matches })
    )

  menuClickHandler() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private observer: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.keyword = this.activatedRoute.snapshot.queryParams?.keyword ?? '';
    this.handleBreakpointScreen();
    this.router.events.pipe(
      withLatestFrom(this.isHandset$),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe(_ => {
      if (this.isExpanded) {
        this.drawer.close()
      }
    });
  }

  onSearch(keyword: any) {
    this.keyword = keyword;
    let queryParams = {
      keyword: this.keyword
    }

    this.updateRouter(queryParams);
  }

  updateRouter(param) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: param,
      queryParamsHandling: 'merge',
    });
  }

  /** Work only page starts or on reload page */
  private handleBreakpointScreen() {
    const matched = this.observer.isMatched('(max-width: 991px)');
    if (matched) {
      this.navbarMode = 'over';
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    } else {
      this.navbarMode = 'side';
      this.isExpanded = true;
    }
  }

  // Working in case screen was resized
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (window.innerWidth <= 991) {
      this.navbarMode = 'over';
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    } else {
      this.navbarMode = 'side';
      this.isExpanded = true;
    }
  }

}
