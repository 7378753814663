import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { generateSrcSet } from 'src/app/helpers/srcset';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { ImageWithSize } from 'src/app/models/image-with-size';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss']
})
export class PictureComponent implements OnInit, AfterViewInit {

  @Input() imgUrl: ImageWithSize;
  @Input() fallbackUrl: string = '';
  @Input() onError: string;
  @Input() isSingleImage: boolean = false;
  @Output() getBgColorCode = new EventEmitter<any>();
  token: string = '';
  srcsets: any[] = [];
  sizes = [];


  constructor(private localService: LocalStorageService) { }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.token = this.localService.get(LocalStorageEnum.token);
    this.srcsets = this.imgUrl ? generateSrcSet(this.imgUrl, this.token) : [];
  }

}
