import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  svgIcon?: string;
  svgWClass?: string;
  svgHClass?: string;
  title?: string;
  message?: string;
  button?: string;
}

@Component({
  selector: 'app-done-dialog',
  templateUrl: './done-dialog.component.html',
  styleUrls: ['./done-dialog.component.scss']
})
export class DoneDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

}
