import { Component, Input, OnInit } from '@angular/core';
import { MenuLists } from './menu';

@Component({
  selector: 'app-search-side-menu',
  templateUrl: './search-side-menu.component.html',
  styleUrls: ['./search-side-menu.component.scss']
})
export class SearchSideMenuComponent implements OnInit {

  @Input() keyword: string = '';
  menuLists = MenuLists;

  constructor() {}

  ngOnInit(): void {}

}
