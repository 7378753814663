import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { Profile } from 'src/app/models/profile';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ShopService } from 'src/app/services/shop.service';
import { UserPortalService } from 'src/app/services/user-portal.service';

@Component({
  selector: 'app-store-mode-layout',
  templateUrl: './store-mode-layout.component.html',
  styleUrls: ['./store-mode-layout.component.scss'],
})
export class StoreModeLayoutComponent implements OnInit {
  isExpanded = true;
  navbarMode: MatDrawerMode = 'side';
  userId: string = '';
  isAuth: Boolean;
  profile: Profile;
  token: string = '';
  profileUrl: string = null;

  @ViewChild('drawer') drawer: MatSidenav;

  isHandset$: Observable<boolean> = this.observer.observe(Breakpoints.Handset)
    .pipe(
      map(result => { return result.matches })
    )

  constructor(
    private authService: AuthService,
    private userPortalService: UserPortalService,
    private localStorageService: LocalStorageService,
    private _shopService: ShopService,
    private observer: BreakpointObserver,
    private router: Router
  ) {
    this.authService.authChange$.subscribe((isAuth) => {
      this.isAuth = isAuth;
      this.userId = localStorageService.get(LocalStorageEnum.user_id);
      if (this.isAuth) {
        this.userPortalService.getProfile().subscribe((data) => {
          this.profile = data;
          this.profileUrl = this.profile?.shops[0]?.photo_url;
          this.localStorageService.set(LocalStorageEnum.shop_id, this.profile?.shops[0]?._id);
        });
      }
    });

    this._shopService.shopProfileChanged$.subscribe((profileUrl: string) => {
      if (profileUrl) {
        this.profileUrl = profileUrl;
      }
    });
  }

  ngOnInit(): void {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
    this.handleBreakpointScreen();
    this.router.events.pipe(
      withLatestFrom(this.isHandset$),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe(_ => {
      if (this.isExpanded) {
        this.drawer.close()
      }
    });
  }

  menuButtonClickHandler() {
    this.isExpanded = !this.isExpanded;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth <= 991) {
      this.navbarMode = 'over';
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    } else {
      this.navbarMode = 'side';
      this.isExpanded = true;
    }
  }

  /** Work only page starts or on reload page */
  private handleBreakpointScreen() {
    const matched = this.observer.isMatched('(max-width: 991px)');
    if (matched) {
      this.navbarMode = 'over';
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    } else {
      this.navbarMode = 'side';
      this.isExpanded = true;
    }
  }
}
