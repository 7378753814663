<ng-container>
  <div class="alert-icon-dialog">
    <h3 class="mat-h3" matDialogTitle>
      {{ "ConfirmDeactivate.DeactivateAccount" | translate }}
    </h3>
  </div>
  <form [formGroup]="passwordForm" (click)="onDeleteClick($event)">
    <div mat-dialog-content>
      <p>{{ "ConfirmDeactivate.Text" | translate }}</p>
      <mat-form-field style="width: 100%; min-height: 100px">
        <mat-label style="padding-top: 2px">{{
          "ConfirmDeactivate.Confirm" | translate
        }}</mat-label>
        <input type="password" formControlName="password" matInput />
        <mat-error *ngIf="passwordForm.controls.password.hasError('required')">
          {{ "ConfirmDeactivate.Required" | translate }}
        </mat-error>
        <mat-error *ngIf="passwordForm.controls.password.hasError('minlength')">
          {{ "ConfirmDeactivate.Length8" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button
        mat-stroked-button
        (click)="onNoClick()"
        class="btn-sm"
        color="primary"
        style="width: 100px"
      >
        <mat-icon>keyboard_backspace</mat-icon>
        {{ "ConfirmDeactivate.Cancel" | translate }}
      </button>
      <button
        mat-flat-button
        class="btn-sm"
        color="warn"
        style="width: 100px"
        type="submit"
      >
        <mat-icon>remove_circle_outline</mat-icon>
        {{ "ConfirmDeactivate.Deactivate" | translate }}
      </button>
    </div>
  </form>
</ng-container>
