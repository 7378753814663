import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('isVisibleChanged', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('1 => 0', animate('10ms')),
      transition('0 => 1', animate('900ms')),
    ]),
  ],
})
export class MainComponent implements OnInit {
  isExpanded = true;
  navbarMode: MatDrawerMode = 'side';
  myRoute: string = '';
  @ViewChild('drawer') drawer: MatSidenav;
  isHandset$: Observable<boolean> = this.observer.observe(Breakpoints.Handset)
    .pipe(
      map(result => { return result.matches })
    )

  constructor(
    private router: Router,
    private observer: BreakpointObserver
  ) {

  }


  ngOnInit(): void {
    this.myRoute = this.router.url;
    this.handleBreakpointScreen();
    this.router.events.pipe(
      withLatestFrom(this.isHandset$),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe(_ => {
      if (this.isExpanded) {
        this.drawer.close()
      }
    });
  }

  /** Work only page starts or on reload page */
  private handleBreakpointScreen() {
    const matched = this.observer.isMatched('(max-width: 991px)');
    if (matched) {
      this.navbarMode = 'over';
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    } else {
      this.navbarMode = 'side';
      this.isExpanded = true;
    }
  }

  // Working in case screen was resized
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (window.innerWidth <= 991) {
      this.navbarMode = 'over';
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    } else {
      this.navbarMode = 'side';
      this.isExpanded = true;
    }
  }

  menuClickHandler() {
    this.isExpanded = !this.isExpanded;
  }

}
