import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyPageComponent } from './components/empty-page/empty-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EmptyComponent } from './components/empty/empty.component';

@NgModule({
  declarations: [
    EmptyPageComponent,
    EmptyComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    EmptyPageComponent,
    EmptyComponent
  ]
})
export class EmptyPageModule { }
