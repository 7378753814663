<div class="side-nav">
  <div class="containers">
    <mat-action-list class="sl-list">
      <h3 class="mat-h3">{{ "MenuSearch.Title" | translate }}</h3>

      <ng-container *ngFor="let menu of menuLists">
        <button class="btn-list-item" mat-list-item [routerLink]="menu.link" [queryParams]="{'keyword': keyword}" routerLinkActive="active">
          <mat-icon [class]="menu.icon.class" [svgIcon]="menu.icon.name"></mat-icon>
          <p class="title">{{ menu.name| translate }}</p>
        </button>
      </ng-container>

    </mat-action-list>
    <app-switch-mode></app-switch-mode>
  </div>
</div>