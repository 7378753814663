<div class="profile-layout bs-container bs-container-lg bs-container-md bs-container-sm">
  <app-header (onMenuClicked)="menuClickHandler()"></app-header>
  <mat-sidenav-container>
    <mat-sidenav #drawer [(opened)]="isExpanded" [mode]="navbarMode">
      <app-left-menu></app-left-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>