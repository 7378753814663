import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-store-mode-header',
  templateUrl: './store-mode-header.component.html',
  styleUrls: ['./store-mode-header.component.scss'],
})
export class StoreModeHeaderComponent implements OnInit {
  @Output() menuButtonClicked = new EventEmitter();
  currentTitleKey: string = '';
  constructor(private router: Router) {
    this.router.events.subscribe((value: any) => {
      if (value.url) {
        this.currentTitleKey = this.mapUrlToKey(value.url);
      }
    });
  }

  ngOnInit(): void { }

  menuClickHandler() {
    this.menuButtonClicked.emit();
  }

  mapUrlToKey(url: string) {
    const firstUrlSegment = url.split('/')[1];
    switch (firstUrlSegment) {
      case 'store-info':
        return 'StoreModeMenu.Dashboard';
      case 'store-info':
        return 'StoreModeMenu.Info';
      case 'my-store-product':
        return 'StoreModeMenu.MyProduct';
      case 'market-demand':
        return 'StoreModeMenu.MarketDemand';
      case 'order-management':
        return 'StoreModeMenu.PurchaseOrder';
      case 'store-message':
        return 'StoreModeMenu.Message';
      case 'my-store-product/products':
        return 'StoreModeMenu.AllProduct';
      case 'payment-account':
        return 'StoreModeMenu.Payment';
      default:
        return '';
    }
  }
}
