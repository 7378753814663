import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss'],
})
export class ProfileLayoutComponent implements OnInit {
  // open = true;
  navbarMode: MatDrawerMode = 'side';
  @ViewChild('drawer') drawer: MatSidenav;

  isHandset$: Observable<boolean> = this.observer.observe(Breakpoints.Handset)
    .pipe(
      map(result => { return result.matches })
    )
  isExpanded: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth <= 768) {
      this.navbarMode = 'over';
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    } else {
      this.navbarMode = 'side';
      this.isExpanded = true;
    }
  }

  menuClickHandler() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(
    private observer: BreakpointObserver,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.handleBreakpointScreen();
    this.router.events.pipe(
      withLatestFrom(this.isHandset$),
      filter(([a, b]) => b && a instanceof NavigationEnd)
    ).subscribe(_ => {
      if (this.isExpanded) {
        this.drawer.close()
      }
    });
  }

  /** Work only page starts or on reload page */
  private handleBreakpointScreen() {
    const matched = this.observer.isMatched('(max-width: 768px)');
    if (matched) {
      this.navbarMode = 'over';
      if (this.isExpanded) {
        this.isExpanded = false;
      }
    } else {
      this.navbarMode = 'side';
      this.isExpanded = true;
    }
  }
}
