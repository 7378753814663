import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseResponse } from '../models/responses/base.response';
import { Unit } from '../models/unit';
import { BaseCrudService } from './base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService extends BaseCrudService<Unit> {

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.portal_url + '/units';
  }

  getUnits(): Observable<BaseResponse> {
    return this.requestService.get<BaseResponse>(
      this.path
    )
  }
}
