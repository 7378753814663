import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-button-send-message',
  templateUrl: './button-send-message.component.html',
  styleUrls: ['./button-send-message.component.scss'],
})
export class ButtonSendMessageComponent implements OnInit {
  @Input() tab: string;
  @Input() user: string;
  @Input() shop: string;
  @Input() profileUrl: string = '';
  @Input() profileName: string = '';
  @Input() isStoreMode: boolean = false;
  @Input() buttonType: string = '';
  @Input() buttonSize: string = '';
  redirectedRoute: string = 'message';

  constructor(
    private router: Router,
    private localService: LocalStorageService,
  ) { }

  ngOnInit(): void { }

  onClickSendMessage(): void {  
    // Check if shop belong to user, if true redirect to store-message
    if (this.tab === 'shop' && this.shop === this.localService.get(LocalStorageEnum.shop_id)) {
      this.redirectedRoute = 'store-message';
      this.router.navigate([this.redirectedRoute], {
        queryParams: {}
      });

      return;
    }

    if (this.isStoreMode) {
      this.redirectedRoute = '/store-message';
    }

    this.router.navigate([this.redirectedRoute], {
      queryParams: {
        tab: this.tab,
        user: this.user,
        shop: this.shop,
      },
    });
  }
}
