export enum LocalStorageEnum {
  token = '_t',
  user_id = '_u',
  user_fullname = '_ufn',
  user_photo_url = '_upu',
  refresh_token = '_rft',
  token_expires_at = '_tea',
  lang = 'lang',
  shop_id = 'shp',
  is_login_with_password = '_lup'
}
