<div class="email-verification">
  <h3 class="mat-h3">{{"EmailVerifyPage.Title" | translate}}</h3>
</div>
<div mat-dialog-content>
  <div class="form-content">
    <img src="../../../../../assets/imgs/img-verify.svg" alt="" />
    <span class="email-label">{{"EmailVerifyPage.Email" | translate}}</span>
    <span class="email-value">{{ form.value.email }}</span>
    <form
      class="container"
      [formGroup]="form"
      autocomplete="off"
      #formDirective="ngForm"
    >
      <input type="hidden" formControlName="email" />
      <ng-otp-input
        [ngClass]="invalidCode ? 'my-otp-input-invalid' : 'my-otp-input'"
        (onInputChange)="onOtpChange($event)"
        [config]="config"
      >
      </ng-otp-input>
      <button
        mat-flat-button
        (click)="onResend()"
        [ngClass]="!isCodeExpired ? 'no-pointer-events' : ''"
      >
        <span *ngIf="!isCodeExpired; else codeExpire">
          {{ resendTime?.min | number: "2.0-0" }} :
          {{ resendTime?.sec | number: "2.0-0" }}
        </span>
        <ng-template #codeExpire>
          <div class="resent-code">
            <span>{{ "EmailVerifyPage.Code" | translate }}</span>
            <span>{{ "EmailVerifyPage.Resend" | translate }}</span>
          </div>
        </ng-template>
      </button>
    </form>
  </div>
</div>
