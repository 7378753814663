import { Province } from './../models/address';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseDatatable } from '../models/datatables/base.datatable';
import { BaseCrudService } from './base-crud.service';
import { environment } from 'src/environments/environment';
import { BaseResponse } from '../models/responses/base.response';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService extends BaseCrudService<Province> {

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.portal_url + '/provinces';
  }

  getProvinces(data?: any): Observable<BaseDatatable<Province>> {
    return this.requestService.getJSON<BaseDatatable<Province>>(
      this.path, { data }
    )
  }

  getDropDownProvince(data?: { per_page?: number, page_number?: number }): Observable<BaseResponse> {
    return this.requestService.get<BaseResponse>(
      this.path, { data }
    )
  }
}
