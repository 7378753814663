import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { Category, Product } from '../models/product';
import { Observable } from 'rxjs';
import { BaseDatatable } from '../models/datatables/base.datatable';
import { RequestPagination } from '../models/request-pagination';
import { BaseResponse } from '../models/responses/base.response';
import { ChatService } from './chat.service';
import { TranslateService } from '@ngx-translate/core';
import { SearchAll } from '../models/search-all.model';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseCrudService<Product> {
  private productPath: string = '';
  private searchAllPath: string = '';
  constructor(
    injector: Injector,
    private chatService :
    ChatService,
    private translate : TranslateService,
    private socket: Socket
  ) {
    super(injector);
    this.path = environment.portal_url + '/products';
    this.productPath = environment.portal_url + '/search/products';
    this.searchAllPath = environment.portal_url + '/search/all';
  }


  listenChatUnseenCount(productID: string) {
    return this.socket.fromEvent<any>('/chats/products/' + productID + '/unseen_count/change');
  }

  getChatUnseenCount(productID: string) {
    return this.socket.emit('/chats/products/unseen_count/get', {product_id: productID});
  }

  // for display pagination
  getProductCategories(data?: RequestPagination): Observable<BaseDatatable<Category>> {
    return this.requestService.getJSON<BaseDatatable<Category>>(
      this.path + '/categories/',
      { data }
    );
  }

  getProducts(data?: {}): Observable<BaseDatatable<Product>> {
    return this.requestService.getJSON<BaseDatatable<Product>>(
      this.path, { data }
    );
  }

  getProductDetail(_id: string): Observable<BaseResponse<Product>> {
    return this.requestService.get<BaseResponse<Product>>(
      this.path + '/' + _id);
  }

  // Service use in shop
  getProductByShopId(shop_id: string, data?: {}): Observable<BaseDatatable<Product>> {
    return this.requestService.getJSON<BaseDatatable<Product>>(
      this.path + '?shop=' + shop_id, { data }
    );
  }

  getProductsCategory(shop_id: string, data?: RequestPagination) {
    return this.requestService.getJSON<BaseDatatable<Product>>(
      environment.portal_url + '/users' + '/shops/' + shop_id + '/products' + '/categories', { data }
    )
  }

  createProduct(data: {}) {
    return this.requestService.postFile(this.path, {
      data,
      option: { is_loading: true },
    });
  }

  updateProduct(_id: string, data: {}) {
    return this.requestService.putFile(this.path + '/' + _id, {
      data,
      option: { is_loading: true },
    });
  }

  deleteProductByID(product_id: string) {
    return this.requestService.deleteJSON(this.path + '/' + product_id);
  }

  // Get product suggestions in news feed
  getProductSugesstion(data: RequestPagination) {
    return this.requestService.getJSON(this.path + '/suggestions', { data })
  }

  postProductRequestQuote(data: {}) {
    return this.requestService.postJSON(this.path + '/request', { data });
  }

  getProductRequest(_id: string, data?: {}) {
    return this.requestService.getJSON(
      'chat/products/' + _id + '/request', { data }
    )
  }

  removePhoto(_id: string, photo_id: string) {
    return this.requestService.deleteJSON(
      this.path + '/' + _id + '/photos/' + photo_id
    );
  }

  // Request quote in product detail
  async productRequestQuote(data: any, unitName) {
    let quote :any = await this.requestService.postJSON(
      this.path + '/request', { data, option: { is_loading: true } }
      ).toPromise();
    return new Promise((resolve)=>{
      this.translate.get("MessagePage.hello_shop_I_want_to_request_quote", {qty : data?.qty , unit : unitName}).subscribe((res : string)=>{

        this.chatService.sendTextMessage(quote?.data.chat, res);
        let sub = this.chatService.subscribeChat(quote?.data?.chat).pipe().subscribe(() => {
          sub.unsubscribe();
          resolve(quote);
        })
      });
    })
  }

  searchProducts(data: {}): Observable<BaseDatatable<Product>> {
    return this.requestService.getJSON(this.productPath, { data })
  }

  searchAll(data: {}):Observable<SearchAll> {
    return this.requestService.getJSON(this.searchAllPath, { data })
  }

}
