import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Category } from 'src/app/models/product';
import _ from 'lodash';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';

export interface ItemData {
  item: Category;
  selected: boolean;
}

@Component({
  selector: 'app-multiselect-autocomplete',
  templateUrl: './multiselect-autocomplete.component.html',
  styleUrls: ['./multiselect-autocomplete.component.scss']
})
export class MultiselectAutocompleteComponent implements OnInit {

  @Output() result = new EventEmitter<{ data: Array<string> }>();
  @Input() data: Array<Category> = [];
  @Input() preSelected: Array<string> = [];

  selectControl = new FormControl();

  rawData: Array<ItemData> = [];
  selectData: Array<ItemData> = [];

  filteredData: Observable<Array<ItemData>>;
  filterString: string = '';
  // localization: string = this.localStorageService.get(LocalStorageEnum.lang);

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    this.filteredData = this.selectControl.valueChanges.pipe(
      startWith<string>(''),
      map(value => typeof value === 'string' ? value : this.filterString),
      map(filter => this.filter(filter))
    );
  }

  ngOnInit(): void {
    this.data.forEach((item: Category) => {
      // when edit, pre select categories
      if (this.preSelected?.length > 0 && this.preSelected.findIndex(pre => pre === item._id) != -1) {
        this.selectData.push({ item: item, selected: true });
        this.rawData.push({ item, selected: true });
      } else {
        this.rawData.push({ item, selected: false });
      }
    });

    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.localization = event.lang;
    // })
  }

  filter = (filter: string): Array<ItemData> => {
    this.filterString = filter;
    if (filter.length > 0) {
      return this.rawData.filter(option => {
        let name = this.translate.currentLang == 'km' ? option.item.name_km : option.item.name_en;
        return name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.rawData.slice();
    }
  };

  displayFn = (): string => '';

  optionClicked = (event: Event, data: ItemData): void => {
    event.stopPropagation();
    this.toggleSelection(data);
  };

  toggleSelection = (data: ItemData): void => {
    data.selected = !data.selected;

    if (data.selected === true) {
      this.selectData.push(data);
    } else {
      const i = this.selectData.findIndex(value => value.item === data.item);
      this.selectData.splice(i, 1);
    }

    // remove selected from dropdown list
    this.rawData.map(rd => {
      if (rd.item._id === data.item._id) {
        rd.selected = data.selected;
      }

      return rd;
    });

    this.selectControl.setValue(this.selectData);
    this.emitAdjustedData();
  };

  emitAdjustedData = (): void => {
    const results: Array<string> = []
    this.selectData.forEach((data: ItemData) => {
      results.push(data.item._id);
    });
    this.result.emit({ data: results });
  };

  removeChip = (data: ItemData): void => {
    this.toggleSelection(data);
  };

}
