import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  token: string = '';

  // listen for user chat list
  chatLists = this.socket.fromEvent<any>('/chats/chat_lists/change');
  // listen for shop chat list
  chatShopLists = this.socket.fromEvent<any>('/chats/shops/chat_lists/change');
  // listen for creating chat
  chatChange = this.socket.fromEvent<any>('/chats/change');

  // listen to unread message count
  unreadMsgCountChange = this.socket.fromEvent(
    '/chats/unread_messages_count/change'
  );
  unreadStoreMsgCountChange = this.socket.fromEvent(
    '/chats/store/unread_messages_count/change'
  );

  constructor(
    private socket: Socket,
    private localStorageService: LocalStorageService
  ) {
    this.loginSocket();
  }

  createUserChat(users: string[]) {
    return this.socket.emit('/chats/create', { users: users });
  }

  createShopChat(shop: string, users: string[] = []) {
    return this.socket.emit('/chats/create', { shop: shop, users });
  }

  getChatList(data: {}) {
    return this.socket.emit('/chats/chat_lists/get', data);
  }

  getShopList(data: {}) {
    return this.socket.emit('/chats/shops/chat_lists/get', data);
  }

  getStoreList(data: {}) {
    return this.socket.emit('/chats/store/chat_lists/get', data);
  }

  sendTextMessage(chatId: string, msg: string) {
    return this.socket.emit('/chats/new', { chat_id: chatId, message: msg });
  }

  editTextMessage(data: {}) {
    return this.socket.emit('/chats/edit', data);
  }

  deleteMessage(data: any) {
    this.socket.emit('/chats/delete', data);
    return this.socket.fromEvent('/chats/' + data.chat_id);
  }

  sendTextPhotoMessage(chatId: string, base64Img: string, msg?: string) {
    return this.socket.emit('/chats/new', {
      chat_id: chatId,
      photo: base64Img,
      message: msg,
    });
  }

  sendVoiceMessage(chatId: string, base64Voice: string) {
    return this.socket.emit('/chats/new', {
      chat_id: chatId,
      voice: base64Voice,
    });
  }

  seenChat(chatId: string) {
    return this.socket.emit('/chats/seen', { chat_id: chatId });
  }

  getChatMessage(chatId: string, data: {}) {
    data['chat_id'] = chatId;
    this.socket.emit('/chats/get', data);
    this.seenChat(chatId);
    return this.socket.fromEvent('/chats/' + chatId);
  }

  getChatListByChatId(chatId: string) {
    this.socket.emit("/chats/chat_lists/id/get", { chat_id: chatId })
    return this.socket.fromEvent(`/chats/chat_lists/${chatId}/change`);
  }

  getUserUnreadMessageCount() {
    return this.socket.emit('/chats/unread_messages_count/get', {});
  }

  getStoreUnreadMessageCount() {
    return this.socket.emit('/chats/store/unread_messages_count/get', {});
  }

  subscribeToRealtimeUnreadMsgCount(userId: string) {
    return this.socket.fromEvent(
      `/user/${userId}/unread_messages_count/change`
    );
  }

  subscribeToRealtimeUnreadStoreMsgCount(userId: string) {
    return this.socket.fromEvent(
      `/user/${userId}/store/unread_messages_count/change`
    );
  }

  subscribeChat(chatId: string) {
    return this.socket.fromEvent('/chats/' + chatId);
  }

  loginSocket() {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
    this.socket.ioSocket['auth'] = {
      token: this.token,
    };
    this.socket.connect();
  }

  logoutSocket() {
    this.socket.ioSocket['auth'] = {
      token: null,
    };
    this.socket.disconnect();
  }
}
