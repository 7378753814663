import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Unit } from 'src/app/models/unit';
import { SellProductDetailDialogueComponent } from 'src/app/routes/sell-product/components/sell-product-detail-dialogue/sell-product-detail-dialogue.component';
import { ProductService } from 'src/app/services/product.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UnitService } from 'src/app/services/unit.service';
@Component({
  selector: 'app-request-quote-product-dialog',
  templateUrl: './request-quote-product-dialog.component.html',
  styleUrls: ['./request-quote-product-dialog.component.scss'],
})
export class RequestQuoteProductDialogComponent implements OnInit {
  units: Unit[] = [];
  form: FormGroup;
  isLoading: boolean = false;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private _route: ActivatedRoute,
    private _unitService: UnitService,
    private _formBuilder: FormBuilder,
    private _productService: ProductService,
    private snackBarService: SnackbarService,
    public dialogRef: MatDialogRef<SellProductDetailDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      qty: ['', Validators.min(1)],
      unit: [''],
    });
    this.getUnits();
    this.patchValue();
  }

  getUnits(): void {
    this._unitService.getUnits().subscribe((res) => {
      this.units = res.data;
    });
  }

  patchValue() {
    this.form.patchValue({
      qty: this.data?.product?.qty,
      unit: this.data?.product?.unit?._id,
    });
  }

  onUnitChange($event): void {
    this.form.patchValue({ unit: $event.value });
  }

  async onSubmit() {
    this.isLoading = true;
    if (this.form.invalid) {
      return;
    }
    try {
      const body: any = {
        qty: this.form.value.qty,
        unit: this.form.value.unit,
        product: this.data?.product?._id,
        shop: this.data?.product?.shop?._id,
      };
      let unitName = this.units.find(
        (unit) => unit._id === this.form.value.unit
      )?.symbol;
      const res: any = await this._productService.productRequestQuote(
        body,
        unitName
      );

      this.isLoading = false;
      if (res.data) {
        this.dialogRef.close({ requested: true });
        this.router.navigateByUrl(
          `/message?tab=shop&shop=${this.data?.product?.shop?._id}`
        );
      }
    } catch (err) {
      console.error(err);
      this.isLoading = false;
      this.snackBarService.onShowSnackbar('Status.NoShop', false);
      this.router.navigate(['/sell-product']);
    }
  }

  // updateRouter(param) {
  //   console.log('params', param);
  //   this.router.navigateByUrl(`/message?type`);
  //   this.router.navigate([], {
  //     relativeTo: this._route,
  //     queryParams: param,
  //     queryParamsHandling: 'merge',
  //   });
  // }

  onBack(data) {
    this.dialogRef.close();
    this.dialog.open(SellProductDetailDialogueComponent, {
      panelClass: 'large-modal',
      data: {
        product: data,
      },
    });
  }
}
