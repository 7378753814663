<div class="account-page">
  <div class="logo-new-password">
    <div class="r-l-w-left">
      <img src="../../../../../assets/imgs/img-key.svg" alt="logo" />
    </div>
  </div>
  <div class="r-l-w-right">
    <div class="content-form">
      <h2 class="mat-h2">{{ "NewPasswordPage.Title" | translate }}</h2>
      <span class="desc">{{ "NewPasswordPage.Desc" | translate }}</span>
      <h3 class="mat-h3">{{ "NewPasswordPage.SubTitle" | translate }}</h3>
      <form
        class="container"
        [formGroup]="form"
        (ngSubmit)="onSubmit(formDirective)"
        autocomplete="off"
        #formDirective="ngForm"
      >
        <div class="form-input">
          <div class="s-t">
            <div class="row">
              <div class="col-sm-6">
                <label class="col-form-label">{{
                  "User.NewPassword" | translate
                }}</label>
                <mat-form-field class="form-control">
                  <mat-label></mat-label>
                  <button
                    type="button"
                    matSuffix
                    mat-icon-button
                    (click)="hide = !hide"
                    [attr.aria-label]="'new password'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon class="open-eye">
                      {{hide ? "visibility_off" : "visibility"}}
                    </mat-icon>
                  </button>
                  <input
                    matInput
                    [type]="hide ? 'password': 'text' "
                    formControlName="password"
                    [placeholder]="'User.Password' | translate"
                  />
                  <mat-error *ngIf="form.get('password')?.hasError('required')">
                    {{ "ValidationErr.Required" | translate }}
                  </mat-error>
                  <mat-error *ngIf="form.get('password').hasError('minlength')">
                    {{"ValidationErr.PasswordLength" | translate}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-6">
                <label class="col-form-label">{{
                  "User.ConfirmPassword" | translate
                }}</label>
                <mat-form-field class="form-control">
                  <mat-label></mat-label>
                  <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="confirmPassword = !confirmPassword"
                    [attr.aria-label]="'confirm password'"
                    [attr.aria-pressed]="confirmPassword"
                  >
                    <mat-icon class="open-eye">
                      {{confirmPassword ? "visibility_off" : "visibility"}}
                    </mat-icon>
                  </button>
                  <input
                    matInput
                    [type]="confirmPassword ? 'password' : 'text'"
                    formControlName="confirm_password"
                    [placeholder]="'User.ConfirmPassword' | translate"
                  />
                  <mat-error *ngIf="form.get('confirm_password')?.errors?.mustMatch">
                    {{ "ValidationErr.ConfirmPasswordNoMatch" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="form-action">
          <button
            mat-stroked-button
            type="submit"
            class="btn-basic btn-lg form-control"
          >
            <span>{{"ActionBtn.ChangePassword" | translate}}</span>
          </button>
        </div>
      </form>
    </div>
    <div class="switch-lang" (click)="authService.changeLanguage()">
      <img src="../../../../../assets/imgs/icon-world.svg" alt="" />
      <span>{{"CurrentLang" | translate}}</span>
    </div>
  </div>
</div>
