<div class="img-editor-container">
    <div class="grid col-lg-12">
        <div class="col-span-lg-10">
            <section class="image-section" [ngStyle]="{background: loading ? 'rgba(1,1,1, 0.4)' : null}">
                <!-- <div class="bg-color"
                    [ngStyle]="{'background-image': 'url(' + (imageFile ? backgroundUrl : backgroundUrl) + ')'}"></div> -->
                <app-image-cropper [imageFile]="imageFile" [imageURL]="imageURL"
                    [maintainAspectRatio]="maintainAspectRatio" [containWithinAspectRatio]="containWithinAspectRatio"
                    [aspectRatio]="aspectRatio" [cropperMinWidth]="128" [onlyScaleDown]="true"
                    [roundCropper]="roundCropper" [canvasRotation]="canvasRotation" [(transform)]="transform"
                    [alignImage]="'center'" [style.display]="showCropper ? null : 'none'"
                    [allowMoveImage]="allowMoveImage" [hidden]="hidden" imageAltText="Alternative image text"
                    backgroundColor="white" output="blob" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"></app-image-cropper>

                <mat-slider *ngIf="roundCropper && !loading" min="1" max="10" step="0.1" showTickMarks discrete
                    [displayWith]="formatLabel" (input)="onScaleImageSlider($event)">
                    <input matSliderThumb>
                </mat-slider>
                <div *ngIf="loading" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </section>

        </div>
        <div class="col-span-lg-2">
            <div class="right-side">
                <section class="navigation">
                    <ul>
                        <li *ngFor="let nav of navigation" [class.active]="nav.isActive"
                            (click)="handleOnNavClick(nav)">
                            <mat-icon>{{nav.icon}}</mat-icon> {{nav.label | translate}}
                        </li>
                    </ul>
                </section>
                <div class="footer">
                    <button mat-stroked-button (click)="OnCanceled()">{{'ActionBtn.Cancel' | translate}}</button>
                    <button mat-flat-button color="primary" (click)="onEditImageSaved()">{{'ActionBtn.Save' |
                        translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>