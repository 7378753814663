import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'commaJoinString',
})
export class CommaJoinStringPipe implements PipeTransform {
  transform(arrayOfString: string[]): string {
    return arrayOfString.filter((s) => !_.isEmpty(s)).join(', ') || 'N/A';
  }
}
