import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NameModule } from '../name/name.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { ProfileImageModule } from '../profile-image/profile-image.module';
import { NotificationItemModule } from '../notification-item/notification-item.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TokenPipeModule } from '../token-pipe/token-pipe.module';
import { EmptyPageModule } from '../empty-page/empty-page.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [NotificationComponent],
  imports: [
    CommonModule,
    MatCardModule,
    RouterModule,
    TranslateModule,
    ScrollingModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    NameModule,
    MatTooltipModule,
    MomentModule,
    ProfileImageModule,
    NotificationItemModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    TokenPipeModule,
    EmptyPageModule,
    MatDividerModule
  ],
  exports: [NotificationComponent],
})
export class NotificationModule { }
