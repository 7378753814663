<div mat-dialog-title class="dialog-header">
    <h3 class="mat-h3">
        {{ "Report.title" | translate }}
    </h3>
    <button class="close-x" mat-icon-button cdkFocusInitial (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-divider class="mb-16"></mat-divider>

<form id="report-dialog-form" class="container-form" [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <div class="form-row">
            <div class="input-column">
                <label class="required">{{ "sellProductDetailPage.Type" | translate }}</label>
                <input type="hidden" formControlName="reportTypes" class="hidden" />
                <app-multiselect-autocomplete class="multi-select" *ngIf="reportTypes?.length" [data]="reportTypes"
                    [preSelected]="form.value.reportTypes" (result)="selectChange($event)"></app-multiselect-autocomplete>
            </div>
        </div>
        <div class="form-row">
            <div class="input-column">
                <label>{{ "Report.reason" | translate }}</label>
                <textarea rows="5" class="custom-textarea" formControlName="reason"></textarea>
            </div>
        </div>
        <div class="upload-image-card-container input-column">
            <label>{{ 'ratingProduct.picture' | translate }}</label>
            <input type="file" id="imageInput" (change)="onFilePicked($event)" #uploader style="display: none"
                accept="image/png,image/gif,image/jpeg,image/jpg,image/bmp" />
            <div class="img-container">
                <div class="img-inset" *ngIf="imageUrls; else insert">
                    <img class="img" [src]="imageUrls" onerror="this.src='assets/imgs/img-no-gallery.svg'" />
                    <div class="remove">
                        <button class="icon-btn-remove" type="button" mat-icon-button (click)="removeFile()">
                            <mat-icon svgIcon="icon-remove"></mat-icon>
                        </button>
                    </div>
                </div>

                <ng-template #insert>
                    <div class="img-inset add-pic" (click)="uploader.value = ''; uploader.click()">
                        <div class="inside-icon">
                            <img src="assets/imgs/icon-add-picture-1.svg" alt="Image gray picture" />
                        </div>
                        <span class="caption-sm">{{ "AddPicture" | translate }}</span>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</form>

<div mat-dialog-actions class="mat-dialog-actions" align="right">
    <button form="report-dialog-form" type="submit" mat-flat-button color="primary" class="btn-md mb-16"
        [disabled]="!form.valid">
        <mat-icon class="svg-path svg-stroke" svgIcon="alert-circle"></mat-icon>
        {{ "Report.title" | translate }}
    </button>
</div>