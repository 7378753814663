<ng-container *ngIf="dialogTemplate === DialogTemplate.DEFAULT; else NO_HEADER;">
    <div class="alert-icon-dialog">
        <h3 class="mat-h3" matDialogTitle>{{ title | translate }}</h3>
    </div>
    <div mat-dialog-content align="center">
        <img [src]="this.icon" alt="Confirm image">
        <p>{{ message | translate: param }}</p>
    </div>
    <div mat-dialog-actions>
        <button *ngIf="isBtnCancel" mat-stroked-button matDialogClose="cancel" color="primary"
            class="btn-cancel btn-sm">
            {{ 'ActionBtn.Cancel' | translate }}
        </button>
        <button *ngIf="button === 'delete'" mat-flat-button matDialogClose="confirm" color="primary"
            class="btn-sm btn-delete">
            {{ 'ActionBtn.Delete' | translate }}
        </button>
        <button *ngIf="button === 'save'" mat-flat-button matDialogClose="confirm" color="warn" class="btn-sm btn-save">
            <mat-icon>save</mat-icon>
            {{ 'ActionBtn.Save' | translate }}
        </button>
        <button *ngIf="button === 'confirm'" mat-flat-button matDialogClose="confirm" color="warn"
            class="btn-sm btn-save">
            <mat-icon>check_circle_outline</mat-icon>
            {{ 'ActionBtn.Confirm' | translate }}
        </button>
        <button *ngIf="button === 'yes'" mat-flat-button matDialogClose="confirm" color="primary"
            class="btn-sm btn-delete">
            {{ 'ActionBtn.Yes' | translate }}
        </button>
    </div>
</ng-container>
<ng-template #NO_HEADER>
    <div mat-dialog-content align="center">
        <img [src]="this.icon" alt="Confirm image">
        <h3 class="caption-md fw-bold !text-primary" matDialogTitle>{{ title | translate }}</h3>
        <p>{{ message | translate: param }}</p>
    </div>
    <div mat-dialog-actions class="custom">
        <button *ngIf="isBtnCancel" mat-stroked-button matDialogClose="cancel" color="primary"
            class="btn-cancel btn-sm">
            {{ 'ActionBtn.Cancel' | translate }}
        </button>
        <div>
            <button *ngIf="button === 'delete'" mat-flat-button matDialogClose="confirm" color="primary"
                class="btn-sm btn-delete">
                {{ 'ActionBtn.Delete' | translate }}
            </button>
            <button *ngIf="button === 'save'" mat-flat-button matDialogClose="confirm" color="warn"
                class="btn-sm btn-save">
                <mat-icon>save</mat-icon>
                {{ 'ActionBtn.Save' | translate }}
            </button>
            <button *ngIf="button === 'confirm'" mat-flat-button matDialogClose="confirm" color="warn"
                class="btn-sm btn-save">
                <mat-icon>check_circle_outline</mat-icon>
                {{ 'ActionBtn.Confirm' | translate }}
            </button>
        </div>
    </div>
</ng-template>
