<div class="chip-list-wrapper">
  <mat-chip-list class="custom-chip-1-wrap" #chipList>
    <ng-container *ngFor="let select of selectData">
      <mat-chip class="cardinal-colors custom-chip-1" (click)="removeChip(select)">
        {{ select.item | apiTranslation }}
        <!-- mapTranslation(select.item) -->
        <mat-icon class="" svgIcon="icon-close-1"></mat-icon>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>

<div class="custom-input multiple-select">
  <input matInput [class.no-padding]="selectData.length > 0" type="text" [matAutocomplete]="auto"
    [formControl]="selectControl">
</div>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngFor="let data of filteredData | async">
    <div (click)="optionClicked($event, data)">
      <mat-checkbox disableRipple="true" color="primary" [checked]="data.selected" (change)="toggleSelection(data)"
        (click)="$event.stopPropagation()">
        {{ data.item | apiTranslation }}
        <!-- mapTranslation(data.item) -->
      </mat-checkbox>
    </div>
  </mat-option>
</mat-autocomplete>