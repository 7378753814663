interface SidenavMenu {
  name: string;
  link: string;
  icon: {
    name: string;
    class: string;
  };
}

export const MenuLists: SidenavMenu[] = [
  {
    name: 'MenuSearch.All',
    link: 'search-all',
    icon: {
      name: 'icon-component',
      class: 'svg-path svg-fill'
    }
  },
  {
    name: 'MenuSearch.User',
    link: 'search-user',
    icon: {
      name: 'icon-username',
      class: 'svg-path svg-fill'
    }
  },
  {
    name: 'MenuSearch.Product',
    link: 'search-product',
    icon: {
      name: 'icon-sell',
      class: 'svg-path svg-fill'
    }
  },
  {
    name: 'MenuSearch.Shop',
    link: 'search-shop',
    icon: {
      name: 'icon-buy',
      class: 'svg-path svg-fill'
    }
  },
  {
    name: 'MenuSearch.Post',
    link: 'search-post',
    icon: {
      name: 'icon-post-normal',
      class: 'svg-path svg-fill'
    }
  },
  {
    name: 'MenuSearch.BuyListing',
    link: 'search-buy-listing',
    icon: {
      name: 'icon-shops',
      class: 'svg-path svg-fill'
    }
  }
]