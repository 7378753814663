<mat-toolbar class="toolbar">
  <!-- newly arrange....... -->

  <button mat-icon-button class="humberger-btn d-lg-none" (click)="menuClickHandler()">
    <mat-icon svgIcon="icon-menu"></mat-icon>
  </button>

  <div class="logo-box col-12" routerLink="/home" (click)="reloadComponent()">
    <img routerLink="/" src="../../../assets/imgs/logo.svg" alt="logo-image" />
    <div class="title d-none d-xl-block">
      <h1 class="mat-h1" translate>AppTitle</h1>
    </div>
  </div>
  <div class="search-component">
    <div class="bg-transit" [class.transit]="showSearch"></div>
    <button class="btn-search" matRipple (click)="toggleSearchForm()" [class.transit]="showSearch">
      <mat-icon svgIcon="icon-search"></mat-icon>
    </button>
    <div class="search-form" [class.transit]="showSearch">
      <button (click)="redirectSearch()" class="icon-search-btn" mat-icon-button aria-label="button with icon search">
        <mat-icon svgIcon="icon-search"></mat-icon>
      </button>
      <input [(ngModel)]="keyword" type="text" (keydown.enter)="redirectSearch()"
        placeholder="{{ 'Search' | translate }}" />
      <button class="icon-btn-close" matRipple (click)="toggleSearchForm()">
        <mat-icon svgIcon="icon-x-close"></mat-icon>
      </button>
    </div>
  </div>

  <div class="spacer"></div>
  <div class="header-action col-12 col-md-2">
    <div class="action">
      <!-- <button mat-icon-button>
        <mat-icon
          svgIcon="icon-chat"
          matBadge="3"
          matBadgeColor="warn"
          matBadgeSize="small"
        ></mat-icon>
      </button> -->
      <app-notification [routeId]="1" [isStoreMode]="false"></app-notification>
    </div>
    <app-profile-menu [isDisplayNoneMd]="true"></app-profile-menu>
  </div>
</mat-toolbar>