import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../shares/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {

  constructor(
    private dialog: MatDialog
  ) { }

  async confirmed() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'small-modal',
      autoFocus: false,
      data: {
        title: 'TextDelete',
        icon: 'assets/imgs/confirm-img.svg',
        message: 'ConfirmMessage.Delete',
        button: 'Delete',
      },
    })

    return await dialog.afterClosed().toPromise() === 'confirm';
  }

}
