import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Buy } from '../models/buy';
import { BaseDatatable } from '../models/datatables/base.datatable';
import { BaseCrudService } from './base-crud.service';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from './chat.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class BuyService extends BaseCrudService<Buy> {

  constructor(
    injector: Injector,
    private chatService: ChatService,
    private translate: TranslateService,
    private socket: Socket
  ) {
    super(injector);
    this.path = environment.portal_url;
  }

  listenChatUnseenCount(buyID: string) {
    return this.socket.fromEvent<any>('/chats/buy-listings/' + buyID + '/unseen_count/change');
  }

  getChatUnseenCount(buyID: string) {
    return this.socket.emit('/chats/buy-listings/unseen_count/get', { buy_listing_id: buyID });
  }

  searchBuyListing(data: {}): Observable<BaseDatatable<Buy>> {
    return this.requestService.getJSON<BaseDatatable<Buy>>(
      this.path + '/search/buy_listings/', { data }
    )
  }

  getbuyListings(data?: {}): Observable<BaseDatatable<Buy>> {
    return this.requestService.getJSON<BaseDatatable<Buy>>(
      this.path + '/users/buy-listings/', { data }
    )
  }

  getMarketDemand(data?: {}): Observable<BaseDatatable<Buy>> {
    return this.requestService.getJSON<BaseDatatable<Buy>>(
      this.path + '/buy-listings/', { data }
    )
  }

  createBuyListing(data: {}) {
    return this.requestService.postFile(
      this.path + '/buy-listings/', { data, option: { is_loading: true } }
    )
  }

  getBuyListingByID(_id: string, data?: {}): Observable<Buy> {
    return this.requestService.getJSON<Buy>(
      this.path + '/buy-listings/' + _id, { data }
    )
  }

  updateBuyListing(_id: string, data: {}) {
    return this.requestService.putFile(
      this.path + '/buy-listings/' + _id, { data, option: { is_loading: true } }
    )
  }

  deleteBuyListingByID(_id: string) {
    return this.requestService.deleteJSON(
      this.path + '/buy-listings/' + _id
    );
  }

  removePhoto(_id: string, photo_id: string) {
    return this.requestService.deleteJSON(
      this.path + '/buy-listings/' + _id + '/photos/' + photo_id
    );
  }

  async offerPrice(data: any, unitSymbol: string, currencySymbol: string) {
    let offer: any = await this.requestService.postJSON(
      this.path + '/buy-listings' + '/offer', { data, option: { is_loading: true } }
    ).toPromise();
    return new Promise((resolve) => {
      this.chatService.sendTextMessage(offer?.data.chat, data?.message);
      let sub = this.chatService.subscribeChat(offer?.data?.chat).pipe().subscribe(() => {
        sub.unsubscribe();
        resolve(offer);
      })
      // this.translate
      //   .get("MessagePage.hello_shop_I_want_to_request_offer",
      //     {
      //       qty: new DecimalPipe('en').transform(data?.qty, '.0') + " " + unitSymbol,
      //       price: new CurrencyPipe('en').transform(data?.price, currencySymbol, 'symbol', '.0')
      //     }
      //   )
      //   .subscribe(async (res: string) => {
      //     await this.chatService.sendTextMessage(offer?.data.chat, res);
      //     let sub = this.chatService.subscribeChat(offer?.data?.chat).pipe().subscribe(() => {
      //       sub.unsubscribe();
      //       resolve(offer);
      //     })
      //   });
    });
  }

  getBuyListingOffer(_id: string, data?: {}) {
    return this.requestService.getJSON(
      'chat/buy-listings/' + _id + '/offer', { data }
    )
  }

  updateBuyListingStatus(_id: string, data: { status: string }) {
    return this.requestService.putJSON(this.path + '/buy-listings/' + _id + '/status', { data });
  }

}
