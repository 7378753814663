import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationItemComponent } from './notification-item.component';
import { MatRippleModule } from '@angular/material/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [NotificationItemComponent],
  imports: [CommonModule, MatRippleModule, AppRoutingModule],
  exports: [NotificationItemComponent],
})
export class NotificationItemModule { }
