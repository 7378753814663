import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './components/main/main.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  MatOptionModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AuthInterceptor } from './intercptors/auth.interceptor';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { SnackbarComponent } from './shares/snackbar/snackbar.component';
import { ConfirmDialogComponent } from './shares/confirm-dialog/confirm-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NameModule } from './shares/name/name.module';
import { MatBadgeModule } from '@angular/material/badge';
import { QuillModule } from 'ngx-quill';
import { MatQuillModule } from './shares/mat-quill/mat-quill-module';
import { MatChipsModule } from '@angular/material/chips';
import { NotificationModule } from './shares/notification/notification.module';

import * as moment from 'moment';
import { MomentModule } from 'ngx-moment';
import { HeaderComponent } from './components/main/header/header.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { ProfileMenuComponent } from './shares/profile-menu/profile-menu.component';
import { ProfileImageModule } from './shares/profile-image/profile-image.module';
import { SeparatorModule } from './shares/separator/separator.module';
import { NgOtpInputModule } from 'ng-otp-input';
import { VerifyEmailDialogModule } from './shares/verify-email-dialog/verify-email-dialog.module';
import { AuthService } from './services/auth.service';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { MatSelectModule } from '@angular/material/select';
import { StoreModeLayoutComponent } from './components/store-mode-layout/store-mode-layout.component';
import { StoreModeHeaderComponent } from './components/store-mode-header/store-mode-header.component';
import { StoreModeMenuComponent } from './components/store-mode-menu/store-mode-menu.component';
import { ProfileLayoutComponent } from './components/profile-layout/profile-layout.component';
import { LeftMenuComponent } from './components/profile-layout/components/left-menu/left-menu.component';
import { StoreModeOfferPriceDialogComponent } from './shares/store-mode-offer-price-dialog/store-mode-offer-price-dialog.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { RequestQuoteProductDialogComponent } from './shares/request-quote-product-dialog/request-quote-product-dialog.component';
import { MultiselectAutocompleteModule } from './shares/multiselect-autocomplete/multiselect-autocomplete.module';
import { CreateProductDialogComponent } from './shares/create-product-dialog/create-product-dialog.component';
import { TelsPipeModule } from './shares/tels-pipe/tels-pipe.module';
import { SharedPipeModule } from './shares/shared-pipe/shared-pipe.module';
import { ButtonSendMessageModule } from './shares/button-send-message/button-send-message.module';
import { TokenPipeModule } from './shares/token-pipe/token-pipe.module';
import { PictureModule } from './shares/picture/picture.module';
import { SearchLayoutComponent } from './components/search-layout/search-layout.component';
import { SearchSideMenuComponent } from './components/search-layout/search-side-menu/search-side-menu.component';
import { SwitchModeComponent } from './components/switch-mode/switch-mode.component';
import { registerLocaleData } from '@angular/common';
import localeKm from '@angular/common/locales/km';
import { ConfirmPasswordDialogComponent } from './shares/confirm-password-dialog/confirm-password-dialog.component';
registerLocaleData(localeKm);

moment.locale('km', {
  months: [
    'មករា',
    'កុម្ភៈ',
    'មីនា',
    'មេសា',
    'ឧសភា',
    'មិថុនា',
    'កក្កដា',
    'សីហា',
    'កញ្ញា',
    'តុលា',
    'វិច្ឆិកា',
    'ធ្នូ',
  ],
  monthsShort: [
    'មករា',
    'កុម្ភៈ',
    'មីនា',
    'មេសា',
    'ឧសភា',
    'មិថុនា',
    'កក្កដា',
    'សីហា',
    'កញ្ញា',
    'តុលា',
    'វិច្ឆិកា',
    'ធ្នូ',
  ],
  monthsParseExact: true,
  weekdays: [
    'ថ្ងៃអាទិត្យ',
    'ថ្ងៃច័ន្ទ',
    'ថ្ងៃអង្គារ',
    'ថ្ងៃពុធ',
    'ថ្ងៃព្រហស្បតិ៍',
    'ថ្ងៃសុក្រ',
    'ថ្ងៃសៅរ៌',
  ],
  weekdaysShort: [
    'អាទិត្យ',
    'ច័ន្ទ',
    'អង្គារ',
    'ពុធ',
    'ព្រហស្បតិ៍',
    'សុក្រ',
    'សៅរ៌',
  ],
  weekdaysMin: ['អា', 'ច', 'អ', 'ព', 'ព្រ', 'សុ', 'ស'],
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY hh:mm A',
    LLLL: 'dddd D MMMM YYYY hh:mm A',
  },
  calendar: {
    sameDay: '[ថ្ងៃនេះ]',
    nextDay: '[ថ្ងៃស្អែក]',
    nextWeek: '[អាទិត្យក្រោយ]',
    lastDay: '[ម្សិលមិញ]',
    lastWeek: '[អាទិត្យមុន]',
    sameElse: 'DD/MM/YYYY',
  },
  relativeTime: {
    future: '%sខាងមុខ',
    past: '%sមុន',
    s: 'បន្តិច',
    m: 'មួយនាទី',
    mm: '%d នាទី',
    h: 'មួយម៉ោង',
    hh: '%d ម៉ោង',
    d: 'មួយថ្ងៃ',
    dd: '%d ថ្ងៃ',
    M: 'មួយខែ',
    MM: '%d ខែ',
    y: 'មួយឆ្នាំ',
    yy: '%d ឆ្នាំ',
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse: /AM|PM/,
  isPM: function (input) {
    return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? 'ព្រឹក' : 'ល្ងាច';
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config: SocketIoConfig = {
  url: environment.chat_url,
  options: {
    transports: ['websocket'],
  },
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NotFoundComponent,
    SnackbarComponent,
    ConfirmDialogComponent,
    HeaderComponent,
    ProfileMenuComponent,
    SideMenuComponent,
    StoreModeLayoutComponent,
    StoreModeHeaderComponent,
    StoreModeMenuComponent,
    ProfileLayoutComponent,
    LeftMenuComponent,
    StoreModeOfferPriceDialogComponent,
    RequestQuoteProductDialogComponent,
    // CreateProductDialogComponent,
    SearchLayoutComponent,
    SearchSideMenuComponent,
    SwitchModeComponent,
    ConfirmPasswordDialogComponent
  ],
  imports: [
    MultiselectAutocompleteModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    RouterModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    NameModule,
    TelsPipeModule,
    MatChipsModule,
    NotificationModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatRippleModule,
    ProfileImageModule,
    SeparatorModule,
    NgOtpInputModule,
    VerifyEmailDialogModule,
    MatSelectModule,
    SharedPipeModule,
    ButtonSendMessageModule,
    TokenPipeModule,
    PictureModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatQuillModule,
    QuillModule.forRoot({
      modules: {
        imageResize: {
          modules: ['Resize', 'DisplaySize'],
          handleStyles: {
            backgroundColor: 'black',
            border: 'none',
            color: 'white',
          },
        },
        toolbar: [['bold', 'italic'], [{ align: [] }], ['link', 'image']],
      },
      placeholder: '*',
    }),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        s: 59,
        m: 59,
        h: 23,
      },
    }),
    SocketIoModule.forRoot(config),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: LOCALE_ID, useValue: 'km-KH' },
    /**
     * Is used for refresh token
     */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  //Define available languages
  availableLang = ['en', 'km'];

  constructor(
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    let tmpLang = 'km';
    //Default brower language
    // const currentLang = window.navigator.language.substring(0, 2);
    // if(this.availableLang.includes(currentLang)){
    //   tmpLang = currentLang;
    // }
    // if (this.authService.isAuth) {
    //   // this.authService.checkLanguagePreference();
    // } else {
    //   translateService.setDefaultLang(tmpLang);
    //   translateService.use(tmpLang);
    //   moment.locale(tmpLang);
    // }
    translateService.setDefaultLang(tmpLang);
    translateService.use(tmpLang);
    moment.locale(tmpLang);
  }
}
