<mat-dialog-content align="center">
    <mat-icon [svgIcon]="data?.svgIcon"
        [ngClass]="[data?.svgWClass ? data?.svgWClass : '!w-150', data?.svgHClass ? data?.svgHClass : '!h-150']">
    </mat-icon>
    <div class="mb-16 text-16 font-bold text-secondary">{{ data?.title | translate }}</div>
    <div class="text-grey text-12">{{ data?.message | translate }}</div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="data?.button" class="justify-content-center mb-0">
    <button mat-flat-button type="submit" color="primary" class="btn-md" [mat-dialog-close]="'accept'" cdkFocusInitial>
        {{ 'Status.accept' | translate }}
    </button>
</mat-dialog-actions>