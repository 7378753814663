import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommaJoinStringPipe } from './comma-join-string.pipe';
import { StatusPipe } from './status.pipe';

@NgModule({
  declarations: [CommaJoinStringPipe, StatusPipe],
  imports: [CommonModule],
  exports: [CommaJoinStringPipe, StatusPipe],
})
export class SharedPipeModule {}
