export enum NotificationTypes {
    NEW_POST = "NEW_POST",
    COMMENT = "COMMENT",
    REPLY = "REPLY",
    LIKE = "LIKE",
    MENTION = "MENTION",
    SHOP_SUBSCRIPTION = "SHOP_SUBSCRIPTION",
    FOLLOWER = "FOLLOWER",
    NEW_ANNOUNCEMENT_FROM_SHOP = "NEW_ANNOUNCEMENT_FROM_SHOP",
    BUY_LISTING_OFFER = "BUY_LISTING_OFFER",
    PRODUCT_REQUEST_QUOTE = "PRODUCT_REQUEST_QUOTE",
    PAYMENT = "PAYMENT",
    VIOLATED_CONTENT = "VIOLATED_CONTENT",
    REPORT_CONTENT = "REPORT_CONTENT",
    RATING = "RATING",
}