import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  @Input() title: string = '';
  @Input() date: string = '';
  @Input() caption: string = '';
  @Input() imageUrl: string =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfF_ZloBA_N-x8_UWTpSojngCvMdTjL7lUgw&usqp=CAU';
  @Input() seen: boolean = true;
  // seen: boolean = true;

  constructor(
  ) { }
  ngOnInit(): void {
  }
}
