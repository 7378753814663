import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tels'
})
export class TelsPipe implements PipeTransform {
  transform(tels: string[]): string {
    if (!tels) return "N/A";
    return tels.join(", ") != "" ? tels.join(", ") : "N/A";
  }
}
