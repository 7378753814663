import { ImageWithSize } from "../models/image-with-size";

export function generateSrcSet(photo: ImageWithSize, token: string) {
  let sortedMetadata = photo.metadata.sort((a, b) => a.width < b.width ? -1 : 1);
  return sortedMetadata?.map(metadata => {
    return {
      size: `(max-width: ${metadata?.width}px)`,
      srcset: photo.url +
        '?size=' +
        metadata.size +
        '&token=' +
        token
    }
  })
}

export function generateSrcSets(photos: ImageWithSize[], token: string) {
  return photos?.map(photo => generateSrcSet(photo, token));
}
