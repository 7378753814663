import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { User } from '../models/profile';
import { BaseDatatable } from '../models/datatables/base.datatable';
import { Follower } from '../models/follower';
import { Post } from '../models/post';
import { Product } from '../models/product';
import { Following } from '../models/following';
import { SearchUser } from '../models/search-user.model';
import { BlockUser } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class UserPortalService extends BaseCrudService<User> {

    private searchPath: string = '';
    constructor(injector: Injector) {
        super(injector);
        this.path = environment.portal_url + '/users';
        this.searchPath = environment.portal_url + '/search/users';
    }

    getProfile() {
        return this.requestService.getJSON<User>(
            this.path + '/profile/about'
        );
    }

    getUserTimeline(_id: string, data: {}): Observable<BaseDatatable<Post>> {
        return this.requestService.getJSON<BaseDatatable<Post>>(
            this.path + '/' + _id + '/timeline/', { data }
        );
    }

    getUserAbout(_id: string) {
        return this.requestService.getJSON<User>(
            this.path + '/' + _id + '/about'
        );
    }

    uploadProfilePhoto(data: {}) {
        return this.requestService.postFile<User>(
            this.path + '/photos/profile', { data: data, option: { is_loading: true } }
        );
    }

    uploadCoverPhoto(data: {}) {
        return this.requestService.postFile<User>(
            this.path + '/photos/cover', { data: data, option: { is_loading: true } }
        );
    }

    getFollower(_id: string, data: {}): Observable<BaseDatatable<Follower>> {
        return this.requestService.getJSON<BaseDatatable<Follower>>(
            this.path + '/' + _id + '/followers', { data }
        );
    }

    getFollowing(_id: string, data: {}): Observable<BaseDatatable<Following>> {
        return this.requestService.getJSON<BaseDatatable<Following>>(
            this.path + '/' + _id + '/followings', { data }
        );
    }

    getFollowerCount(_id: string) {
        return this.requestService.getJSON(
            this.path + '/' + _id + '/followers/count'
        );
    }

    subscribeUser(_id: string, data?: { subscribe?: boolean }) {
        return this.requestService.postJSON(
            this.path + '/subscribe/' + _id, { data }
        );
    }

    getProducts(data: {}): Observable<BaseDatatable<Product>> {
        return this.requestService.getJSON(
            this.path + '/products', { data }
        );
    }

    getFollowShops(_id: string, data: {}): Observable<BaseDatatable<Follower>> {
        return this.requestService.getJSON<BaseDatatable<Follower>>(
            this.path + '/' + _id + '/subscribed_shops', { data }
        )
    }

    searchUsers(data: {}): Observable<BaseDatatable<SearchUser>> {
        return this.requestService.getJSON<BaseDatatable<SearchUser>>(
            this.searchPath, { data }
        )
    }

    getUserBlockList(data: { per_page: number, page_number: number }): Observable<BaseDatatable<BlockUser>> {
        return this.requestService.getJSON<BaseDatatable<BlockUser>>(this.path + '/blocks', { data });
    }

    blockUser(user: string, data: { block: boolean }) {
        return this.requestService.putJSON<any>(`${this.path}/${user}/blocks`, { data });
    }

    deactivateAccount(password: string) {
        return this.requestService.postJSON(`${this.path}/deactivate`, { data: { password } });
    }
}
