<div class="content-button">
  <div class="border"></div>
  <button *ngIf="profile?.shops.length > 0" class="profile-content" mat-stroked-button
    [routerLink]="['/my-store-product']">
    <div class="swap-img">
      <mat-icon class="icon-swap" svgIcon="icon-swap-shop">
        <app-profile-image class="profile-images" [profileStyle]="{ width: '24px', height: '24px' }"
          className="title-md font-bold title-profile-shop" [profileImage]="
                profile?.shops[0]?.photo_url | token : 'assets/imgs/official-store.svg'">
        </app-profile-image>
      </mat-icon>
      <h4>{{ "SwitchToMyStore" | translate }}</h4>
    </div>
  </button>
  <div class="text">{{ "Copyright2023" | translate }}</div>
  <a class="link" href="policy">{{ "privacyPolicy" | translate }}</a>
</div>