import { Injectable, Injector } from '@angular/core';
import { Post } from '../models/post';
import { BaseCrudService } from './base-crud.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { BaseDatatable } from '../models/datatables/base.datatable';
import { RequestPagination } from '../models/request-pagination';
import { FavoritePost } from '../models/favorite';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService extends BaseCrudService<Post> {
  constructor(injector: Injector) {
    super(injector);
    this.path = environment.portal_url + '/favorites';
  }

  savePost(data: { post_id: string }) {
    return this.requestService.postJSON(this.path + '/posts', { data });
  }

  saveProduct(data: { product_id: string }) {
    return this.requestService.postJSON(this.path + '/products', { data });
  }

  unsavePost(_id: string) {
    return this.requestService.deleteJSON(this.path + '/posts/' + _id);
  }

  unsaveProduct(_id: string) {
    return this.requestService.deleteJSON(this.path + '/products/' + _id);
  }

  unsaveShop(_id: string) {
    return this.requestService.deleteJSON(this.path + '/shops/' + _id);
  }

  saveBuyListing(data: { buy_listing_id: string }) {
    return this.requestService.postJSON(this.path + '/buy-listings', { data });
  }

  unsavedBuyListing(_id: string) {
    return this.requestService.deleteJSON(this.path + '/buy-listings/' + _id);
  }

  getFavorites(
    data: RequestPagination
  ): Observable<BaseDatatable<FavoritePost>> {
    return this.requestService.getJSON<BaseDatatable<FavoritePost>>(this.path, {
      data,
    });
  }

  saveShop(data: {}) {
    return this.requestService.postJSON(this.path + '/shops', { data });
  }
}
