import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit {
  @Input() title?: string;
  @Input() shortDescription?: string;
  @Input('image') src: string = 'assets/imgs/no-result.svg';
  @Input() btnName?: string;
  @Input() hasBtn?: boolean = false;
  @Output() createBtnEmitter = new EventEmitter<any>();

  constructor(
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    if (!this.btnName) this.btnName = this.translate.instant('ActionBtn.Create');
  }

}
