import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import {
  NotificationElement,
  NotificationResponse,
} from '../models/responses/notification.response';
import { BaseCrudService } from './base-crud.service';
import { environment } from '../../environments/environment';
import { RequestPagination } from '../models/request-pagination';
import { BaseDatatable } from '../models/datatables/base.datatable';

export enum NotificationMode {
  USER = 'USER',
  STORE = 'STORE'
}
@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseCrudService<null> {
  constructor(injector: Injector) {
    super(injector);
    this.path = environment.portal_url + '/notifications';
  }

  getNotifications(
    data: RequestPagination & { mode: string }
  ): Observable<BaseDatatable<NotificationElement>> {
    return this.requestService.getJSON<BaseDatatable<NotificationElement>>(
      this.path,
      { data }
    );
  }

  getNotificationById(id: string): Observable<any> {
    return null;
    //     return this.requestService.getJSON<any>('/notification/lms/' + id);
  }

  markAllAsRead(): Observable<any> {
    return null;
    //     return this.requestService.patch<any>('/notification/lms/mark_read_all')
  }

  markAllAsSeen(data: any) {
    return this.requestService.postJSON(this.path + '/seen', {
      data: data,
    });
  }

  countUnseen(data: { mode: string }) {
    return this.requestService.getJSON(this.path + '/count/unseen', { data });
  }
}
