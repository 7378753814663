import { Component, OnInit } from '@angular/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { Profile } from 'src/app/models/profile';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserPortalService } from 'src/app/services/user-portal.service';

@Component({
  selector: 'app-switch-mode',
  templateUrl: './switch-mode.component.html',
  styleUrls: ['./switch-mode.component.scss']
})
export class SwitchModeComponent implements OnInit {
  // Switch from Profile to shop profile purpose only
  profile: Profile;
  constructor(
    private userPortalService: UserPortalService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.getProfile();
  }

  async getProfile() {
    this.profile = await this.userPortalService.getProfile().toPromise();
  }

}
