import { Province } from '../../models/address';
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Category, Product } from 'src/app/models/product';
import { User } from 'src/app/models/profile';
import { BuyService } from 'src/app/services/buy.service';
import { ProductService } from 'src/app/services/product.service';
import { ProvinceService } from 'src/app/services/province.service';
import { Unit } from '../../models/unit';
import { UnitService } from '../../services/unit.service';
import { CurrencyService } from '../../services/currency.service';
import { Currency } from '../../models/currency';
import { Buy } from 'src/app/models/buy';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmDialogComponent } from 'src/app/shares/confirm-dialog/confirm-dialog.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CategoryService } from 'src/app/services/category.service';
import { PostStatus } from 'src/app/models/enums/post-status.enum';
import { CreateProductType } from 'src/app/models/enums/create-product-type.enum';
import { environment } from 'src/environments/environment';
import { DeleteService } from 'src/app/services/delete.service';
import { ImageResult } from '../image-cropper/components/image-alteration/image-alteration.component';
import { Regex } from 'src/app/models/enums/regex.enum';

enum PageSectionEnum {
  CREATE_PRODUCT = "CREATE_PRODUCT",
  EDIT_IMAGE = "EDIT_IMG"
}
interface Image {
  file?: File;
  url?: string;
  base64?: string;
  isNew: boolean;
  imageId?: string;
}
@Component({
  selector: 'app-create-product-dialog',
  templateUrl: './create-product-dialog.component.html',
  styleUrls: ['./create-product-dialog.component.scss'],
})
export class CreateProductDialogComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  @ViewChild('uploader') input: ElementRef<HTMLInputElement>;
  currencies: Currency[] = [];
  categories: Category[] = [];
  imageFiles: File[] = [];
  imageUrls: any[] = [];
  form: FormGroup;
  units: Unit[] = [];
  isEdit: boolean = false;
  profile: User;
  product: Product;
  provinces: Province[] = [];
  buy: Buy = null;
  isLoading: boolean = false;
  maxUploadFiles: number = 10;
  addOnBlur = true;
  tels: string[] = [];
  createType: string = '';
  CREATE_TYPE = CreateProductType;

  /** New Variable Edition v1.0.1 */
  images: Image[] = [];
  imageIndex: number;
  editedImageBlob: Blob;
  editedImageUrl: string;
  PageSectionEnum = PageSectionEnum;
  pageSection: PageSectionEnum = PageSectionEnum.CREATE_PRODUCT;

  imageURL: string;
  editImageFile: File;
  base64Regex = new RegExp("^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$");

  constructor(
    private dialog: MatDialog,
    private _buyService: BuyService,
    private _formBuilder: FormBuilder,
    private _unitService: UnitService,
    private _productService: ProductService,
    private _provinceService: ProvinceService,
    private _currencyService: CurrencyService,
    private snackbarService: SnackbarService,
    private categoryService: CategoryService,
    private deleteService: DeleteService,
    public dialogRef: MatDialogRef<CreateProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getUnits();
    this.getCurrencies();
    this.getProvinces();
    this.getProductCategories();
    this.profile = this.data.profile;
    this.createType = this.data.type;

    if (this.data?.buy) {
      this.isEdit = true;
      this.buy = this.data?.buy;
      this.tels = this.data?.buy?.contact?.tels.filter(
        (tel) => tel != '' && tel != null
      );
      const arrCategories = this.data?.buy?.categories.map(
        (category) => category._id
      );
      this.patchValue(this.data?.buy, arrCategories);
    }

    if (this.data?.product) {
      this.product = this.data?.product;
      this.isEdit = true;
      this.tels = this.data?.product?.contact?.tels.filter(
        (tel) => tel != '' && tel != null
      );

      // when edit, transform categories array object to array of string
      let customCategories = this.data?.product?.categories;
      if (customCategories.length && typeof customCategories[0] === 'object') {
        customCategories = customCategories.map((pc) => pc._id);
      }

      this.patchValue(this.data?.product, customCategories);
    }
  }

  initForm(): void {
    this.form = this._formBuilder.group({
      _id: [''],
      unit: [''],
      tels: ['', Validators.pattern(Regex.contactNumberRegex)],
      address: ['', Validators.maxLength(100)],
      currency: [''],
      description: [''],
      to_price: [''],
      qty: ['', Validators.required],
      title: ['', [Validators.required, Validators.maxLength(150)]],
      province: [''],
      categories: ['', Validators.required],
      from_price: ['', Validators.required],
    });

    if (this.createType === this.CREATE_TYPE.BUY_LISTING) {
      this.form.controls['province'].setValidators([Validators.required]);
    }

    this.form.get('from_price').valueChanges.subscribe(() => {
      this.validateToPrice();
    });

    this.form.get('to_price').valueChanges.subscribe(() => {
      this.validateToPrice();
    });
  }

  onCurrencyChange($event): void {
    this.form.patchValue({ currency: $event.value });
  }

  getProductCategories() {
    this.categoryService.getProductCategories({ per_page: 200, page_number: 1 }).subscribe((res) => {
      this.categories = res.data;
    });
  }

  getProvinces(): void {
    this._provinceService.getDropDownProvince().subscribe((res) => {
      this.provinces = res.data;
    });
  }

  getUnits(): void {
    this._unitService.getUnits().subscribe((res) => {
      this.units = res.data;
      if (!this.data?.buy && !this.data?.product) {
        this.form.patchValue({ unit: this.units[0]._id });
      }
    });
  }

  getCurrencies(): void {
    this._currencyService.getCurrencies().subscribe((res) => {
      this.currencies = res.data;
      if (!this.data?.buy && !this.data?.product) {
        this.form.patchValue({ currency: this.currencies[0]._id });
      }
    });
  }

  patchValue(formData, categories): void {
    this.form.patchValue({
      title: formData?.title,
      description: formData?.description,
      categories: categories,
      currency: formData?.currency?._id,
      qty: formData?.qty,
      unit: formData?.unit?._id,
      from_price: formData?.prices[0],
      to_price: formData?.prices[1],
      address: formData?.contact?.address,
      province: formData?.contact?.province?._id,
      shop: this.data.shopID,
    });
    // this.imageUrls = this.embeddedTokenToImages(formData?.photo_urls);
    for (let i = 0; i < formData.photos.length; i++) {
      this.images.push({
        imageId: formData.photos[i],
        url: this.embeddedTokenImage(`${environment.file_portal_url}${formData.photos[i]}`, 'l'),
        isNew: false
      })
    }
  }

  private embeddedTokenImage(photoUrl: string, size?: string): string {
    if (!photoUrl || photoUrl === '') return '';
    if (!photoUrl.includes('?token')) {
      photoUrl = photoUrl + '?token=' + this.data?.token + (size ? ('&size=' + size) : '');
    }

    return photoUrl;
  }

  private embeddedTokenToImages(photo_urls): string[] {
    if (photo_urls?.length <= 0) return [];
    return photo_urls?.map((e) => {
      if (!e.includes('?token')) {
        e = e + '?token=' + this.data?.token;
      }
      return e;
    });
  }

  exceedMaxImageUploading(): boolean {
    if (this.images.length > this.maxUploadFiles) {
      this.snackbarService.onShowSnackbar(
        'ValidationErr.MaxFileUploading',
        false
      );
      return true;
    }
    return false;
  }

  pickFile() {
    if (this.exceedMaxImageUploading()) return;
    this.input.nativeElement.value = '';
    this.input.nativeElement.click();
  }

  onFilePicked(e) {
    const files = e.target.files;
    const maxSize = 30 * 1024 * 1024; // 30MB limit
    if (files[0] && !files[0].type.match('image.*')) {
      this.snackbarService.onShowSnackbar(
        'ValidationErr.ChooseImageTypeOnly',
        false
      );
      return;
    }

    if (files[0].size > maxSize) {
      this.snackbarService.onShowSnackbar(
          'ValidationErr.MaxFileSizeExceeded',
          false
      );
      return
    }

    if (files.length > 0) {
      let imageName = files[0].name;
      if (imageName.lastIndexOf('.') <= 0) return;
      // let maxLoop = this.findMaxLoop(
      //   files.length,
      //   this.imageUrls.length,
      //   this.maxUploadFiles
      // );
      let numImagesToUpload = Math.min(files.length, 10);

      if ((this.images.length + numImagesToUpload) > this.maxUploadFiles) {
        this.snackbarService.onShowSnackbar(
          'ValidationErr.MaxFileUploading',
          false
        );
        return;
      }

      if ((this.images.length + numImagesToUpload) > 10) {
        this.snackbarService.onShowSnackbar(
          'ValidationErr.MaxImagesLimitReached',
          false
        );
        return;
      }

      for (let i: number = 0; i < numImagesToUpload; i++) {
        const fr = new FileReader();
        fr.readAsDataURL(files[i]);
        fr.addEventListener('load', () => {
          // this.imageUrls.push(fr.result);
          // this.imageFiles.push(files[i]);
          this.images.push({
            file: files[i],
            base64: fr.result.toString(),
            isNew: true
          })
        });
      }
    }
  }

  private findMaxLoop(newFileLength, currentImageUrlsLength, maxLength): number {
    let remainFileToAdd = maxLength - currentImageUrlsLength;
    return newFileLength > remainFileToAdd
      ? newFileLength - (newFileLength - remainFileToAdd)
      : newFileLength;
  }

  // removeFile(index: number) {
  //   if (this.isEdit && (this.buy?.photos[index]) || this.product?.photos[index]) {
  //     const ref = this.dialog.open(ConfirmDialogComponent, {
  //       panelClass: 'small-modal',
  //       data: {
  //         title: 'TextDelete',
  //         icon: 'assets/imgs/confirm-img.svg',
  //         message: 'ConfirmMessage.Delete',
  //         button: 'Delete',
  //       },
  //     });
  //     ref.afterClosed().subscribe(async (res) => {
  //       if (res == 'confirm') {
  //         if (this.createType === this.CREATE_TYPE.PRODUCT) {
  //           await this._removeProductFile(index);
  //         } else {
  //           await this._removeBuyListingFile(index);
  //         }
  //       }
  //     });
  //   } else {
  //     this.imageUrls.splice(index, 1);
  //     this.imageFiles.splice(index, 1);
  //   }
  // }

  async removeFile(index: number) {
    // when edit remove image from db
    if (this.isEdit && !this.images[index].isNew && this.images[index].imageId) {
      if (await this.deleteService.confirmed()) {
        //Remove photo from database
        // await this._removeProductFile(index);
        if (this.createType === this.CREATE_TYPE.PRODUCT) {
          await this._removeProductFile(index);
        } else {
          await this._removeBuyListingFile(index);
        }
      }
    } else {
      this.images.splice(index, 1);
    }
  }

  private async _removeProductFile(index: number) {
    try {
      this.isLoading = true;
      await this._productService.removePhoto(this.product._id, this.images[index].imageId).toPromise();
      this.isLoading = false;

      // this.imageUrls.splice(index, 1);
      this.images.splice(index, 1);
      this.product.photos.splice(index, 1);
      this.product.photo_urls.splice(index, 1);
      this.snackbarService.onShowSnackbar('Status.DeleteSuccess');
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  private async _removeBuyListingFile(index: number) {
    try {
      this.isLoading = true;
      await this._buyService.removePhoto(this.buy._id, this.images[index].imageId).toPromise();
      this.isLoading = false;

      // this.imageUrls.splice(index, 1);
      this.images.splice(index, 1);

      this.buy.photos.splice(index, 1);
      this.buy.photo_urls.splice(index, 1);
      this.snackbarService.onShowSnackbar('Status.DeleteSuccess');

    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }

  }

  formCtrlError = (fileName: string): boolean | undefined => {
    return this.form.get(fileName)?.invalid && this.form.get(fileName)?.touched;
  };

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.isLoading = true;
      const formValue = this.form.value;
      let newImages: File[] = [];
      for (let img of this.images) {
        if (img.isNew) {
          newImages.push(img.file);
        }
      }
      const body = {
        title: formValue.title,
        description: formValue.description,
        categories: formValue.categories,
        currency: formValue.currency,
        qty: formValue.qty,
        unit: formValue.unit,
        'contact[address]': formValue.address,
        'contact[province]': formValue.province,
        'contact[tels]': this.tels,
        file: newImages,
        status: PostStatus.PUBLISHED,
        shop: this.data?.shopID,
      };
      if (formValue.to_price) {
        body['prices'] = [formValue.from_price, formValue?.to_price]
      } else {
        body['prices'] = [formValue.from_price]
      }
      if (this.isEdit) {
        if (this.createType === this.CREATE_TYPE.PRODUCT) {
          this.updateProduct(body);
        } else {
          this.updateBuyListing(body);
        }
      } else {
        if (this.createType === this.CREATE_TYPE.PRODUCT) {
          this.createProduct(body);
        } else {
          this.createBuyListing(body);
        }
      }
    }
  }

  updateProduct(body): void {
    this._productService.updateProduct(this.data?.product?._id, body).subscribe(
      (res) => {
        this.dialogRef.close(res);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        console.error(err?.message);
      }
    );
  }

  createProduct(body): void {
    this._productService.createProduct(body).subscribe(
      (res) => {
        this.dialogRef.close(res);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        console.error(err?.message);
      }
    );
  }

  createBuyListing(body): void {
    this._buyService.createBuyListing(body).subscribe(
      (res) => {
        this.dialogRef.close(res);
        this.isLoading = false;
      },
      (err) => {
        console.error(err?.message);
        this.isLoading = false;
      }
    );
  }

  updateBuyListing(body): void {
    this._buyService.updateBuyListing(this.data?.buy?._id, body).subscribe(
      (res) => {
        this.dialogRef.close(res);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        console.error(err?.message);
      }
    );
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.tels.push(value);
    }
    event.chipInput!.clear();
  }

  remove(tel: any): void {
    const index = this.tels.indexOf(tel);
    if (index >= 0) {
      this.tels.splice(index, 1);
    }
  }

  selectChange = (event: any) => {
    this.form.controls.categories.setValue(event.data);
  };

  onPageSectionChanged(sectionName: PageSectionEnum, idx: number = null) {
    switch (sectionName) {
      case PageSectionEnum.CREATE_PRODUCT:
        this.pageSection = PageSectionEnum.CREATE_PRODUCT;
        break;
      case PageSectionEnum.EDIT_IMAGE:
        this.pageSection = PageSectionEnum.EDIT_IMAGE;
        this.imageIndex = idx;
        if (this.images[idx].isNew) {
          this.imageURL = null;
          this.editImageFile = this.images[idx].file;
        } else {
          this.editImageFile = null;
          this.imageURL = this.images[idx].url;
        }
        break;
      default:
        break;
    }
  }

  private async removeImageFromDB(imageId: string) {
    try {
      this.isLoading = true;

      if (this.product?._id) {
        this._productService.removePhoto(this.product._id, imageId).toPromise();
        this.isLoading = false;
      } else if (this.buy?._id) {
        this._buyService.removePhoto(this.buy?._id, imageId).toPromise();
        this.isLoading = false;
      }
      this.snackbarService.onShowSnackbar('Status.DeleteSuccess');

    } catch (error) {
      console.error(error);
      this.isLoading = false;
      this.snackbarService.onShowSnackbar(
        'Status.DeleteFailed',
        false
      );
    }
  }


  async onEditImageSaved(event: ImageResult) {
    if (!this.images[this.imageIndex].isNew && this.images[this.imageIndex].imageId) {
      // remove from DB
      this.removeImageFromDB(this.images[this.imageIndex].imageId);
    }

    // update it to existing index
    this.images[this.imageIndex] = {
      file: event.file,
      base64: event.base64,
      isNew: true
    }
    this.onPageSectionChanged(PageSectionEnum.CREATE_PRODUCT);
  }

  onCancelImageClick(event) {
    if (!event) return;
    this.onPageSectionChanged(PageSectionEnum.CREATE_PRODUCT);
  }

  validateToPrice() {
    const toPriceControl = this.form.get('to_price');
    const fromPrice = this.form.get('from_price')?.value;
    const toPrice = toPriceControl?.value;
    if (toPrice !== null && toPrice !== '' && parseFloat(toPrice) <= parseFloat(fromPrice)) {
      toPriceControl.setErrors({ 'invalidPrice': true });
    } else {
      toPriceControl.setErrors(null);
    }
  }
}
