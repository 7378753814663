<form class="container-form" [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <div mat-dialog-title class="back-page">
    <mat-icon *ngIf="data?.enableBackButton" (click)="onBack(data.product)" svgIcon="icon-back"></mat-icon>
    <h3 class="mat-h3">
      {{ "sellProductDetailPage.RequestPrice" | translate }}
    </h3>
  </div>
  <div mat-dialog-content>
    <div class="caption-md">
      {{ "Note" | translate }}
    </div>
    <div class="caption-md">
      {{ "HowMuchDoYouNeedToBuy" | translate }}
    </div>
    <div class="flex-row">
      <div class="input-column">
        <label>{{ "sellProductDetailPage.Quantity" | translate }}</label>
        <div class="input-group with-select">
          <input type="number" formControlName="qty" />
          <mat-select [disabled]="true" class="custom-select custom-mat-select" formControlName="unit"
            (selectionChange)="onUnitChange($event)">
            <mat-option *ngFor="let item of units" [value]="item._id">{{
              item?.name
              }}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mat-dialog-actions btn-action" align="right">
    <button [disabled]="isLoading || (this.form.get('qty').invalid || !this.form.get('qty').value) " type="submit"
      mat-flat-button color="primary" class="btn-md">
      <mat-icon class="svg-path svg-fill" svgIcon="icon-contact"> </mat-icon>
      {{ "sellProductDetailPage.RequestPrice" | translate }}
    </button>
  </div>
</form>