<div class="profile-image {{profileSize}}">
  <img class="image-cover" [ngStyle]="profileStyle"
    [src]="profileImage ? profileImage : 'assets/imgs/avatar-person.svg'"
    onerror="this.src='assets/imgs/avatar-person.svg'" />
  <div class="profile-name" [class.max-width]="wraperWidth" [class.d-md-down-none]="isDisplayNoneMd">
    <span class="market-name" [ngClass]="className">
      {{ isNormalCase? fullName : (fullName | uppercase )}}
      <ng-container *ngIf="post">
        <span style="font-size: 12px; font-weight: normal" *ngIf="
                  (post?.post_type == postType.ARTICLE ||
                    post?.post_type == postType.CAPTION) &&
                  post?.mention_users?.length > 0
                ">
          <span class="caption-sm">{{ "With" | translate }}</span>
          <a class="title" [routerLink]="['/profile', post?.mention_users[0]?._id]">
            {{ post?.mention_users[0] | fullName: "" }}
          </a>
          <span>
            {{ post?.mention_users?.length > 1 ? ("And" | translate) : "" }}
          </span>
          <a class="title" (click)="onSeeTagOther.emit()" *ngIf="post?.mention_users?.length > 1">
            {{
            post?.mention_users?.length < 3 ? ("Post.TagOther" | translate) : ("Post.TagOther" | translate: { value:
              post?.mention_users?.length - 1 }) }} </a>
        </span>
      </ng-container>
    </span>
    <span [routerLink]="post ? '/post/' + post?._id : null" [ngClass]="classCaption">{{caption}}
      <ng-container *ngIf="post">
        | {{ 'PostType.' + post?.post_type | translate }}
      </ng-container>
    </span>
  </div>
</div>