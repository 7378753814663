import { Category } from './../models/product';
import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseCrudService } from './base-crud.service';
import { BaseResponse } from '../models/responses/base.response';
import { Observable } from 'rxjs';
import { BaseDatatable } from '../models/datatables/base.datatable';
@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseCrudService<Category> {

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.portal_url;
  }

  // for dropdown list
  getProductCategories(data?: {}) {
    return this.requestService.getJSON<BaseDatatable<Category>>(
      this.path + '/product-categories/', { data }
    )
  }
}
