import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { BaseDatatable } from '../models/datatables/base.datatable';
import { ReportContent } from '../models/report';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseCrudService<null> {

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.portal_url;
  }

  getAllReportType(): Observable<{ _id: string; name_en: string; name_km: string }[]> {
    return this.requestService.getJSON(this.path + '/report-types');
  }

  postReportContent(data: any) {
    return this.requestService.postFile(this.path + '/report-contents', { data, option: { is_loading: true } });
  }

  getReportContentActivities(id: string) {
    return this.requestService.getJSON<BaseDatatable<ReportContent>>(this.path + '/report-contents/' + id + '/report-content-activities');
  }

  // to make a serious or formal request 
  appealReportContent(data: any) {
    return this.requestService.postFile(this.path + '/report-contents/appeal', { data, option: { is_loading: true } });
  }

}
