import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { RequestParam } from '../models/request-param';
import { User } from '../models/profile';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends BaseCrudService<User> {

    constructor(injector: Injector) {
        super(injector);
        this.path = environment.auth_url;
    }

    registerUserPassword(data: RequestParam){
        return this.requestService.postJSON<User>(
            this.path + '/register-user-password', data
        );
    }

    verifyEmail(data: RequestParam){
        return this.requestService.postJSON(
            this.path + '/verify-email', data
        );
    }

    resendEmailVerification(data: RequestParam){
        return this.requestService.postJSON(
            this.path + '/resend-verify-email', data
        );
    }
}
