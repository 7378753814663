import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {
  Component,
  Input,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { NotificationTypes } from 'src/app/models/enums/notification-type.enum';
import { ViolatedContentEnum } from 'src/app/models/enums/violated-content.enum';
import { NotificationType } from 'src/app/models/notification-type';
import { RequestPagination } from 'src/app/models/request-pagination';
import { NotificationResponse } from 'src/app/models/responses/notification.response';
import { Notification } from 'src/app/models/user-suggestion';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { NotificationService, NotificationMode } from 'src/app/services/notification.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() profileUrl: string =
    'https://live.staticflickr.com/5209/5238144567_33495083b8_c.jpg';
  isMenuOpen: boolean;

  dataSource: NotificationsDataSource;
  notificationTypes = NotificationTypes;
  @Input() routeId: number;
  @Input() type: string = '';
  @Input() partner: string = '';
  redirectedRoute: string = 'message';
  redirectedRouteStoreMode: string = '/store-message';
  @Input() isStoreMode: boolean = false;

  queryParam = {
    type: this.type,
    partner: this.partner,
  };

  badgeCount: string = '0';
  throttle = 100;
  scrollDistance = 1;
  notifications: any[] = [];
  requestPagination: RequestPagination & { mode: string } = {
    page_number: 1,
    per_page: 50,
    mode: ''
  };
  token: string = '';
  isLoading: boolean = false;

  constructor(
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private snackbarService: SnackbarService,
    private router: Router,
    public translateService: TranslateService,
  ) {

  }
  ngOnInit(): void {
    this.requestPagination.mode = this.isStoreMode ? NotificationMode.STORE : NotificationMode.USER
    this.getUnseen();
    this.token = this.localStorageService.get(LocalStorageEnum.token);
  }

  ngOnChanges(changes: SimpleChange): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // if (changes.routeId) {
    this.dataSource = new NotificationsDataSource(this.notificationService);
    NotificationsDataSource.isOpen = false;
    this.isMenuOpen = false;
    // }
  }

  getUnseen() {
    this.notificationService.countUnseen({ mode: this.requestPagination.mode }).subscribe((res) => {
      this.badgeCount = (res['count'] | 0).toString();
      if (parseInt(this.badgeCount) > 9) {
        this.badgeCount = '9+';
      }
    });
  }

  markAllAsRead(): void {
    this.notificationService.markAllAsRead().subscribe((res) => {
      if (res.status === 1) {
        this.dataSource = new NotificationsDataSource(this.notificationService);
      }
    });
  }

  async getNotifications() {
    try {
      this.isLoading = true;

      const response = await this.notificationService
        .getNotifications(this.requestPagination)
        .toPromise();

      this.isLoading = false;
      if (this.notifications.length === 0) {
        this.notifications = response.data;
      } else {
        this.notifications.push(...response.data);
      }

      this.setNotificationsSeen(this.findUnseenNotifications(response.data));
    } catch (error) {
      console.log('error', error);
      this.isLoading = false;
    }
  }

  findUnseenNotifications(requestedNotifications: any[]) {
    const unseen = requestedNotifications
      .filter((item) => {
        return item.seen === false;
      })
      .map((unseenItem) => {
        return unseenItem._id;
      });
    return unseen;
  }

  async setNotificationsSeen(unseenNotifications: string[]) {
    if (unseenNotifications.length === 0) {
      return;
    }

    try {
      const data = {
        id: unseenNotifications,
      };
      const res = await this.notificationService
        .markAllAsSeen(data)
        .toPromise();
      this.badgeCount = (res['count'] | 0).toString();
      if (parseInt(this.badgeCount) > 9) {
        this.badgeCount = '9+';
      }
    } catch (error) {
      console.log('set seen error', error);
    }
  }

  menuClosed() {
    this.isMenuOpen = false;
  }

  menuOpened() {
    this.isMenuOpen = true;
    this.notifications = [];
    this.requestPagination.page_number = 1;
    this.getNotifications();
    NotificationsDataSource.isOpen = true;
    //used to prevent when remove the menu from the dom and lost data
    // this.cdkVirtualScrollViewport.setRenderedContentOffset(0);
    //set  10 items to be rendered
    // this.cdkVirtualScrollViewport.setRenderedRange({ start: 0, end: 9 });
  }

  onScroll(): void {
    if (this.isLoading) {
      return;
    }
    this.requestPagination.page_number++;
    this.getNotifications();
  }

  gotoPage(notification: NotificationType) {
    if (!notification?.type) return;
    switch (notification.type) {
      case this.notificationTypes.NEW_POST:
        this.router.navigate([`post/${notification?.metadata?.post}`]).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.COMMENT:
        this.router.navigate([`post/${notification?.metadata?.post}`]).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.REPLY:
        this.router.navigate([`post/${notification?.metadata?.post}`]).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.LIKE:
        this.router.navigate([`post/${notification?.metadata?.post}`]).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.SHOP_SUBSCRIPTION:
        this.router.navigate([`users/${notification?.metadata?.user}`]).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.FOLLOWER:
        this.router.navigate([`users/${notification?.metadata?.user}`]).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.BUY_LISTING_OFFER:
        this.router.navigate([this.redirectedRoute], {
          queryParams: { tab: 'shop', shop: notification?.metadata?.shop },
        }).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.PRODUCT_REQUEST_QUOTE:
        this.router.navigate([this.redirectedRouteStoreMode], {
          queryParams: { tab: 'shop', user: notification?.metadata?.user },
        }).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.NEW_ANNOUNCEMENT_FROM_SHOP:
        this.router.navigate([`shop/shop-detail/${notification?.metadata?.shop}/product/${notification?.metadata?.product}`]).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.PAYMENT:
        this.router.navigate([this.redirectedRouteStoreMode], {
          queryParams: { tab: 'shop', user: notification?.metadata?.user },
        }).then(() => {
          window.location.reload();
        });
        break;
      case this.notificationTypes.VIOLATED_CONTENT:
        this.navigateToViolatedContent(notification);
        break;
      case this.notificationTypes.REPORT_CONTENT:
        // this.navigateToViolatedContent(notification);
        break;
      case this.notificationTypes.RATING:
        this.router.navigate(['/my-order', notification?.metadata?.order], {
          queryParams: { 'order-detail': notification?.metadata?.order }
        }).then(() => {
          window.location.reload();
        });
        break;
      // case 'NEW_PRODUCT_POSTED':
      //   this.router.navigateByUrl('/shop/' + notification?.metadata?.shop);
      //   break;
      // case 'NEW_SHOP':
      //   this.router.navigateByUrl('/shop/' + notification?.metadata?.shop);
      //   break;
      // case 'MENTION':
      //   break;
      default:
        break;
    }
  }

  private navigateToViolatedContent(notifType: NotificationType) {
    let reportContent: string;
    let id: string;

    if (notifType.metadata.post) {
      reportContent = ViolatedContentEnum.POST;
      id = notifType?.metadata?.post;
    } else if (notifType.metadata.product) {
      reportContent = ViolatedContentEnum.PRODUCT;
      id = notifType?.metadata?.product;
    } else if (notifType.metadata.shop) {
      reportContent = ViolatedContentEnum.SHOP;
      id = notifType?.metadata?.shop;
    } else if (notifType.metadata.buy_listing) {
      reportContent = ViolatedContentEnum.BUY_LISTING;
      id = notifType?.metadata?.buy_listing;
    }

    if (!reportContent) return;

    this.router.navigate([`report-content/${reportContent}/${id}`]).then(() => {
      window.location.reload();
    });
  }

  getNotificationTitle(notification: NotificationType) {
    let title = notification?.title || 'Unknown';
    switch (notification.type) {
      case this.notificationTypes.VIOLATED_CONTENT:
        title = this.translateService.instant('Report.Moderator');
        break;
      case this.notificationTypes.REPORT_CONTENT:
        title = this.translateService.instant('Report.Moderator');
        break;
      default:
        break;
    }

    return title;
  }
}

export class NotificationsDataSource extends DataSource<any | undefined> {
  private cachedNotifications = Array.from<any>({ length: 0 });
  private dataStream = new BehaviorSubject<(any | undefined)[]>(
    this.cachedNotifications
  );

  private subscription = new Subscription();
  private pageSize = 10;
  private lastPage = 1;
  public unreadCount: number;
  public count: number;
  public isLoading: boolean;
  static isOpen: boolean;

  constructor(private notificationService: NotificationService) {
    super();
    this._fetchNotificationPage();
  }

  connect(
    collectionViewer: CollectionViewer
  ): Observable<(any | undefined)[] | ReadonlyArray<any | undefined>> {
    this.subscription.add(
      collectionViewer.viewChange.subscribe((range) => {
        const currentPage = this._getPageForIndex(range.end);

        if (NotificationsDataSource.isOpen && currentPage == this.lastPage) {
          this.lastPage++;
          this._fetchNotificationPage();
        }
      })
    );

    return this.dataStream;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subscription.unsubscribe();
  }

  private _fetchNotificationPage() {
    this.isLoading = true;

    let data = [];
    for (let i: number = 0; i < 10; i++) {
      const temp = {
        _id: '321',
        user: {
          first_name: 'P',
          last_name: 'R',
        },
        type: 'like',
        createdAt: new Date(),
      };
      data.push(temp);
    }
    const result: NotificationResponse = {
      _id: '123',
      status: 1,
      notification_count: data.length,
      document_count: 1,
      data: data,
    };
    // this.notificationService.getNotifications({page_number: 1, per_page: 10}).subscribe(
    //   (res) => {
    //     // console.log(res);
    //     this.cachedNotifications = this.cachedNotifications.concat(res.data);
    //     this.dataStream.next(this.cachedNotifications);
    //     this.unreadCount = result.notification_count;
    //     this.count = result.document_count;
    //     this.isLoading = false;
    //   }
    // );

    this.cachedNotifications = this.cachedNotifications.concat(result.data);
    this.dataStream.next(this.cachedNotifications);
    this.unreadCount = result.notification_count;
    this.count = result.document_count;
    this.isLoading = false;

    // this.notificationService.getAllNotifications(10, this.lastPage).pipe(
    //   catchError((err) => {
    //     return throwError(err);
    //   })
    // )
    // .subscribe(
    //   (res) => {

    //     if(res.status == 1){
    //       this.cachedNotifications = this.cachedNotifications.concat(res.data);
    //       this.dataStream.next(this.cachedNotifications);
    //       this.unreadCount = res.notification_count;
    //       this.count = res.document_count;
    //       this.isLoading = false;
    //     }
    //   },
    //   (err) =>{
    //     this.isLoading = false;
    //   }
    // );
  }

  private _getPageForIndex(i: number): number {
    return Math.floor(i / this.pageSize);
  }
}