import { BaseCrudService } from './base-crud.service';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { Shop } from '../models/shop';

import { BaseDatatable } from '../models/datatables/base.datatable';
import { RequestPagination } from '../models/request-pagination';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchShop } from '../models/search-shop.model';

@Injectable({
  providedIn: 'root',
})
export class ShopService extends BaseCrudService<Shop> {
  shopProfileChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  private searchPath: string = '';

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.portal_url + '/shops';
    this.searchPath = environment.portal_url + '/search/shops';
  }

  getShops(data?: {}) {
    return this.requestService.getJSON<BaseDatatable<Shop>>(this.path, {
      data,
    });
  }
  getShopSuggestions(data: RequestPagination) {
    return this.requestService.getJSON<BaseDatatable<Shop>>(
      this.path + '/suggestions',
      { data }
    );
  }

  subscribeShop(_id: string, data?: { subscribe?: boolean }) {
    return this.requestService.postJSON(this.path + '/' + _id + '/subscribe', {
      data,
    });
  }

  getShopDetail(_id: string) {
    return this.requestService.getJSON<Shop>(
      this.path + '/' + _id
    );
  }

  getShopByID(_id: string, data?: {}) {
    return this.requestService.getJSON<BaseDatatable<Shop>>(
      this.path + '/' + _id,
      { data }
    );
  }

  // Update shop info
  manageShop(_id: string, data?: {}) {
    return this.requestService.putJSON(this.path + '/' + _id, {
      data,
      option: { is_loading: true },
    });
  }

  uploadShopProfileOrCover(_id: string, data?: {}) {
    return this.requestService
      .postFile<Shop>(this.path + '/' + _id + '/photos', { data })
      .pipe(
        map((res) => {
          if (res) {
            this.shopProfileChanged$.next(res.photo_url);
          }
          return res;
        })
      );
  }

  getShopFollower(shop_id: string, data?: {}) {
    return this.requestService.getJSON(
      this.path + '/' + shop_id + '/followers',
      { data }
    );
  }

  searchShops(data: {}): Observable<BaseDatatable<Shop>> {
    return this.requestService.getJSON<BaseDatatable<Shop>>(
      this.searchPath, { data }
    )
  }
}
