import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dimensions } from '../../interfaces/dimensions.interface';
import { ImageCroppedEvent } from '../../interfaces/image-cropped-event.interface';
import { ImageTransform } from '../../interfaces/image-transform.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSliderChange } from '@angular/material/slider';

export interface ImageResult {
  file: File,
  base64: string;
}

interface Sidenav {
  id: number;
  icon: string;
  svgIcon?: string;
  label: string;
  isActive?: boolean;
  func?: any[]
}

@Component({
  selector: 'app-image-alteration',
  templateUrl: './image-alteration.component.html',
  styleUrls: ['./image-alteration.component.scss']
})
export class ImageAlterationComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation?: number;
  translateH = 0;
  translateV = 0;
  scale = 1;
  aspectRatio = 16 / 9;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {
    translateUnit: 'px'
  };
  loading = false;
  allowMoveImage = false;
  hidden = false;
  editedImageBlob: Blob;
  editedImageUrl: string;
  backgroundUrl: string;

  @Input() imageFile: File;
  @Input() imageURL?: string;
  @Input() roundCropper: boolean = false;
  @Input() maintainAspectRatio: boolean = false;
  @Output() onImageSaved = new EventEmitter<ImageResult>();
  @Output() onCanceled = new EventEmitter<boolean>();

  navigation: Sidenav[] = [
    {
      id: 0,
      icon: "crop",
      label: "ActionBtn.Crop",
      isActive: false
    },
    {
      id: 1,
      icon: "rotate_right",
      label: "ActionBtn.RotateRight",
      isActive: false,
      func: ["this.rotateRight()"]
    },
    {
      id: 2,
      icon: "rotate_left",
      label: "ActionBtn.RotateLeft",
      isActive: false,
      func: ["this.rotateLeft()"]
    }
  ]

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.selectNavigationIndex();
    this.imageCropperConfigurationSettings();
    // this.getBackgroundImage();
    this.loading = true;
  }

  private imageCropperConfigurationSettings() {
    if (this.roundCropper) {
      this.aspectRatio = 4 / 4;
      this.maintainAspectRatio = true;
    }
  }

  private selectNavigationIndex(selectedIndex: number = 0) {
    this.navigation[selectedIndex].isActive = true;
  }

  private getBackgroundImage() {
    if (this.imageFile) {
      //   console.log("file: ", this.imageFile)
      //   let reader = new FileReader();
      //  reader.readAsDataURL(this.imageFile);
      //  reader.addEventListener("load", () => {
      //   reader.result.
      //  })
      this.backgroundUrl = URL.createObjectURL(this.imageFile);

    }
  }

  handleOnNavClick(nav: Sidenav) {
    this.navigation.forEach(val => val.isActive = false);
    nav.isActive = !nav.isActive;
    if (nav.func?.length > 0) {
      eval("(" + nav.func + ")");
    }
  }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl || event.base64 || '');
    this.editedImageBlob = event.blob; //new File(event.blob, "d")
    // let reader = new FileReader();
    // reader.readAsDataURL(event.blob);
    // reader.addEventListener("load", () => {
    //   this.editedImageUrl = reader.result.toString();
    // })
  }

  private blobToFile(blob: Blob, fileName: string): File {
    const b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    return blob as File;
  }

  imageLoaded() {
    this.showCropper = true;
    this.loading = false;
    // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
    this.loading = false;
  }

  loadImageFailed() {
    this.loading = false;
    console.error('Load image failed');
  }

  rotateLeft() {
    this.loading = true;
    setTimeout(() => { // Use timeout because rotating image is a heavy operation and will block the ui thread
      this.canvasRotation--;
      this.flipAfterRotate();
    });
  }

  rotateRight() {
    this.loading = true;
    setTimeout(() => {
      this.canvasRotation++;
      this.flipAfterRotate();
    });
  }

  moveLeft() {
    this.transform = {
      ...this.transform,
      translateH: ++this.translateH
    };
  }

  moveRight() {
    this.transform = {
      ...this.transform,
      translateH: --this.translateH
    };
  }

  moveTop() {
    this.transform = {
      ...this.transform,
      translateV: ++this.translateV
    };
  }

  moveBottom() {
    this.transform = {
      ...this.transform,
      translateV: --this.translateV
    };
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
    this.translateH = 0;
    this.translateV = 0;
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {
      translateUnit: 'px'
    };
  }

  zoomOut(): void {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn(): void {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  onScaleImageSlider(event: MatSliderChange) {
    this.scale = event.value;
    this.transform = {
      ...this.transform,
      scale: event.value
    };
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + '%';
    }

    return `${value}`;
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  toggleAspectRatio() {
    this.aspectRatio = this.aspectRatio === 4 / 3 ? 16 / 5 : 4 / 3;
  }

  onEditImageSaved() {
    const file: File = this.imageFile;
    let filename: string = file?.name ? file.name : new Date().toDateString();
    let modifyFile: File = this.blobToFile(this.editedImageBlob, filename);
    // console.log("cropped Image: ", this.croppedImage)
    this.onImageSaved.emit({
      file: modifyFile,
      base64: this.croppedImage
    })
  }

  OnCanceled() {
    this.onCanceled.emit(true);
  }

}
