<div class="header-wrapper">
  <div class="left-box">
    <button type="button" class="menu-btn" matRipple (click)="menuClickHandler()">
      <mat-icon svgIcon="icon-menu"></mat-icon>
    </button>
    <h1 class="page-title">{{ currentTitleKey | translate }}</h1>
  </div>
  <div class="right-box">
    <app-notification [isStoreMode]="true"></app-notification>
    <app-profile-menu [isDisplayNoneMd]="true"></app-profile-menu>
  </div>
</div>