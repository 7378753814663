import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Subscription } from 'rxjs';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';

@Component({
  selector: 'app-store-mode-menu',
  templateUrl: './store-mode-menu.component.html',
  styleUrls: ['./store-mode-menu.component.scss'],
})
export class StoreModeMenuComponent implements OnInit, OnDestroy {
  unreadMsgCount: number = 0;
  unreadMsgCountSubscription: Subscription;
  realtimeUnreadMsgCountSubscription: Subscription;
  userId: string;

  constructor(
    private localStorageService: LocalStorageService,
    private chatService: ChatService
  ) { }

  ngOnInit(): void {
    if (this.unreadMsgCountSubscription) {
      this.unreadMsgCountSubscription.unsubscribe();
    }
    if (this.realtimeUnreadMsgCountSubscription) {
      this.realtimeUnreadMsgCountSubscription.unsubscribe();
    }
    this.getUnreadMsgCount();
    this.unreadMsgCountSubscription = this.chatService.unreadStoreMsgCountChange
      .pipe()
      .subscribe(this.onUnreadMsgCountChange.bind(this));
    this.userId = this.localStorageService.get(LocalStorageEnum.user_id);
    this.realtimeUnreadMsgCountSubscription = this.chatService
      .subscribeToRealtimeUnreadStoreMsgCount(this.userId)
      .pipe()
      .subscribe(this.onRealtimeUnreadMsgCountChange.bind(this));
  }

  ngOnDestroy(): void {
    if (this.unreadMsgCountSubscription) {
      this.unreadMsgCountSubscription.unsubscribe();
    }
    if (this.realtimeUnreadMsgCountSubscription) {
      this.realtimeUnreadMsgCountSubscription.unsubscribe();
    }
  }

  getUnreadMsgCount() {
    this.chatService.getStoreUnreadMessageCount();
  }

  onUnreadMsgCountChange(value: any) {
    this.unreadMsgCount = Number(value.data.unread_messages_count);

    //unsubscribe since it is only trigger when called.
    this.unreadMsgCountSubscription.unsubscribe();
  }

  onRealtimeUnreadMsgCountChange(value: any) {
    this.unreadMsgCount = Number(value.data.unread_messages_count);
  }
}
