import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RequestErrorEnum } from 'src/app/models/enums/request-error.enum';
import { VERIFY_TYPE } from 'src/app/models/enums/verify-type.enum';
import { NewPasswordComponent } from 'src/app/routes/forget-password/components/new-password/new-password.component';
import { ConfirmSuccessComponent } from 'src/app/routes/register/components/confirm-success/confirm-success.component';
import { ForgetPasswordService } from 'src/app/services/forget-password.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-verify-email-dialog',
  templateUrl: './verify-email-dialog.component.html',
  styleUrls: ['./verify-email-dialog.component.scss'],
})
export class VerifyEmailDialogComponent implements OnInit {
  form: FormGroup;
  verifyType: string = '';
  invalidToken: boolean = false;
  codeLength: number = 6;
  isGetCode: boolean = false;
  isCodeExpired: boolean = false;
  invalidCode: boolean = false;
  resendTime: { min: number; sec: number };
  config = {
    allowNumbersOnly: true,
    length: 6,
  };
  emailToken: string = '';
  verifyCode: string = '';
  token: string = '';
  // this variable used to solving submitted multiple times when copy pased code
  isSubmited = false;
  constructor(
    private fb: FormBuilder,
    private registrationService: RegistrationService,
    private snackBarService: SnackbarService,
    private forgetpasswordService: ForgetPasswordService,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isSubmited = false;
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      code: [null, [Validators.required, Validators.minLength(6)]],
    });
    this.verifyType = this.data?.verifyType;
    this.isCodeExpired = false;
    this.startTimer();
    if (this.data) {
      if (this.data.email) {
        this.form.patchValue({
          email: this.data.email,
        });

        this.emailToken = this.data.email;
      }
    }
  }
  onSubmit() {
    if (this.form.valid) {
      this.isSubmited = true;
      const formValue = this.form.value;

      if (this.verifyType === VERIFY_TYPE.REGISTER) {
        const data = {
          email: formValue.email,
          code: formValue.code,
        };
        this.registerVerifyEmail(data);
      } else if (this.verifyType === VERIFY_TYPE.FORGET_PASSWORD) {
        const data = {
          email: formValue.email,
          token: formValue.code,
        };
        this.forgetPasswordVerifyEmail(data);
      }
    }
  }

  forgetPasswordVerifyEmail(data): void {
    this.forgetpasswordService.verifyEmailForgetPassword({ data }).subscribe(
      (res) => {
        this.onCloseDialog();
        this.invalidToken = false;
      },
      (err) => {
        this.invalidToken = true;
        console.error(err);
        if (err && err?.error_type === RequestErrorEnum[400]) {
          this.snackBarService.onShowSnackbar('Status.IncorrectCode', false);
        } else {
          this.snackBarService.onShowSnackbar('Status.SomethingWrong', false);
        }
      }
    );
  }

  registerVerifyEmail(data): void {
    this.registrationService.verifyEmail({ data }).subscribe(
      (res) => {
        this.openDialogSuccess();
        setTimeout(() => {
          this.onCloseDialog();
        }, 2000);
        this.isSubmited = false;
        this.invalidCode = false;
      },
      (err) => {
        this.invalidCode = true;
        console.error(err);
        this.isSubmited = false;
        if (err && err?.error_type === RequestErrorEnum[401]) {
          this.snackBarService.onShowSnackbar('Status.IncorrectCode', false);
        } else {
          this.snackBarService.onShowSnackbar('Status.SomethingWrong', false);
        }
      }
    );
  }

  openDialogSuccess() {
    this.dialog.open(ConfirmSuccessComponent, {
      panelClass: 'medium-modal',
      data: {},
    });
  }

  openChangePasswordPage() {
    this.dialog.open(NewPasswordComponent, {
      panelClass: 'large-modal',
    });
  }

  onCloseDialog() {
    if (this.verifyType === VERIFY_TYPE.REGISTER) {
      this.dialog.closeAll();
      this.router.navigate(['/home']);
    } else if (this.verifyType === VERIFY_TYPE.FORGET_PASSWORD) {
      this.dialog.closeAll();
      this.router.navigate(['/forget-password/new-password'], {
        queryParams: { email: this.emailToken, token: this.form.value.code },
      });
    }
  }

  onOtpChange(value) {
    this.invalidCode = false;
    this.form.patchValue({
      code: value,
    });

    if (value.length === this.codeLength && !this.isSubmited) {
      this.onSubmit();
    }
  }

  otpTimer;
  startTimer() {
    clearInterval(this.otpTimer);
    this.resendTime = {
      min: 2,
      sec: 0,
    };
    this.otpTimer = setInterval(() => {
      if (this.resendTime.sec - 1 == -1) {
        this.resendTime.min -= 1;
        this.resendTime.sec = 59;
      } else {
        this.resendTime.sec -= 1;
      }
      if (this.resendTime.min === 0 && this.resendTime.sec === 0) {
        clearInterval(this.otpTimer);
        this.isCodeExpired = true;
      }
    }, 1000);
  }

  onResend() {
    if (!this.isCodeExpired) {
      return;
    }
    if (this.verifyType === VERIFY_TYPE.REGISTER) {
      const data = {
        email: this.emailToken,
      };
      this.onResendEmailRegister(data);
    } else if (this.verifyType === VERIFY_TYPE.FORGET_PASSWORD) {
      const data = {
        email: this.emailToken,
      };
      this.onResendEmailForgetPassword(data);
    }
  }

  onResendEmailRegister(data) {
    this.registrationService.resendEmailVerification({ data }).subscribe(
      (res) => {
        this.isCodeExpired = false;
        this.startTimer();
      },
      (err) => {
        // this.onMessage('ការបញ្ជូនម្តងទៀតបរាជ័យ', false);
      }
    );
  }

  onResendEmailForgetPassword(data) {
    this.forgetpasswordService
      .resendEmailVerifyForgetPassword({ data })
      .subscribe(
        (res) => {
          this.isCodeExpired = false;
          this.startTimer();
        },
        (err) => {}
      );
  }
}
