<div class="empty-page-wrapper">
  <img src="assets/imgs/empty-page.svg" alt="" srcset="" />
  <div class="title">
    <h1 class="mat-h1">
      {{ "LetGetStarted" | translate }}
    </h1>
    <h4 class="mat-h4">
      {{ "ClickTheButtonAtTheBottomToAddYouProduct" | translate }}
    </h4>
    <button class="mat-h4" mat-flat-button color="primary" class="btn-md">
      <mat-icon svgIcon="icon-post-normal"></mat-icon>
      {{ "ActionBtn.Post" | translate }}
    </button>
  </div>
</div>