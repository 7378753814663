import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { Profile } from 'src/app/models/profile';
import { AuthService } from 'src/app/services/auth.service';
import { LanguagePreferenceService } from 'src/app/services/language-preference.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ShopService } from 'src/app/services/shop.service';
import { UserPortalService } from 'src/app/services/user-portal.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {
  authSubscribe;
  isAuth: Boolean;
  userId: string = '';
  token: string = '';
  profile: Profile;
  isMyProfile: Boolean = false;
  currentLanguage: string;
  isHome: boolean = false;
  isShopMenu: boolean = false;
  shopProfileImage: string = null;
  routesInStoreMode = [
    '/store-info',
    '/my-store-product',
    '/order-management',
    '/market-demand',
    '/my-store-product/products',
    '/store-message',
    '/payment-account'
  ];
  getLang: string = '';
  isLoginWithPassword: boolean = true;
  routerPrefix: string;

  @Input() isDisplayNoneMd: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userPortalService: UserPortalService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private languagePreferenceService: LanguagePreferenceService,
    private _shopService: ShopService,
    private dialog: MatDialog
  ) {

    this.isLoginWithPassword = this.localStorageService.get(LocalStorageEnum.is_login_with_password) === 'true';

    this.authSubscribe = authService.authChange$.subscribe((isAuth) => {
      this.isAuth = isAuth;
      this.userId = localStorageService.get(LocalStorageEnum.user_id);
      if (this.isAuth) {
        this.userPortalService.getProfile().subscribe((data) => {
          this.profile = data;
          this.shopProfileImage = this.profile?.shops[0]?.photo_url;
          this.localStorageService.set(LocalStorageEnum.shop_id, data.shops[0]._id);
        });
      }
    });

    this._shopService.shopProfileChanged$.subscribe((profileUrl: string) => {
      if (profileUrl) {
        this.shopProfileImage = profileUrl;
      }
    });

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        const href = event.url.split('/');
        this.isMyProfile = false;
        if (event.url.startsWith('/profile')) {
          if (href.length > 2) {
            const id = href[2].split('?')[0];
            if (this.localStorageService.get(LocalStorageEnum.user_id) == id) {
              this.isMyProfile = true;
            }
          } else {
            //No id at the end
            this.isMyProfile = true;
          }
        }
      }
    });

    this.routerPrefix = this.router.url.substring(0, this.router.url.indexOf('/', 1) !== -1 ? this.router.url.indexOf('/', 1) : this.router.url.length);

    if (
      this.routesInStoreMode.findIndex(
        (element) => element === this.routerPrefix
      ) != -1
    ) {
      this.isHome = false;
    } else {
      this.isHome = true;
    }
  }

  ngOnInit(): void {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
    this.updateLanguage();
  }

  async updateLanguage() {
    try {
      const res = await this.languagePreferenceService
        .getPreference()
        .toPromise();
      if (res) {
        this.getLang = res?.data?.preference?.language;
        this.translateService.use(this.getLang);
        this.translateService.setDefaultLang(this.getLang);
        this.localStorageService.set(LocalStorageEnum.lang, this.getLang);
      }
    } catch (err) {
      console.error(err);
    }
  }

  linkUserAndStoreMode() {
    if (
      this.routesInStoreMode.findIndex(
        (element) => element === this.routerPrefix
      ) != -1
    ) {
      this.router.navigateByUrl('/home');
    } else {
      this.router.navigateByUrl('/my-store-product');
    }
  }

  logout(): void {
    // this.isAuth = false;
    // this.authSubscribe.unsubscribe();
    // this.authService.logout();
    // this.router.navigateByUrl('/login');

    const ref = this.dialog.open(ConfirmDialogComponent,
      {
        width: '500px',
        data: {
          title: 'Logout',
          icon: 'assets/imgs/Mobile Logout1.svg',
          message: 'ConfirmMessage.Logout',
          button: 'confirm'
        }
      }
    );
    ref.afterClosed().subscribe(
      (res) => {
        if (res == 'confirm') {
          this.isAuth = false;
          this.authSubscribe.unsubscribe();
          this.authService.logout();
          this.router.navigateByUrl('/login');
        }
      }
    );
  }

  async changeLanguagePreference() {
    const fromCurrentLang = this.translateService.currentLang;
    const toLang = fromCurrentLang === 'km' ? 'en' : 'km';
    const data = {
      language: toLang,
    };
    try {
      await this.languagePreferenceService.updatePreference(data).toPromise();
      // if not error, change language
      this.translateService.use(toLang);
      this.translateService.setDefaultLang(toLang);
      this.localStorageService.set(LocalStorageEnum.lang, toLang);
    } catch (err) {
      console.error(err);
      // if got error, use current language
      this.translateService.use(fromCurrentLang);
      this.translateService.setDefaultLang(fromCurrentLang);
    }
  }
}
