<div mat-dialog-title class="dialog-header">
  <h3 class="mat-h3">
    {{ "MakeOffer" | translate }}
  </h3>
  <button class="close-x" mat-icon-button cdkFocusInitial (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider class="mb-16"></mat-divider>

<form id="offer-price-dialog-form" class="container-form" [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div>
      <span class="text-red font-bold">{{ "Note" | translate }}</span>
      <span class="text-grey">&nbsp;{{"marketDemand.providePrice" | translate }}</span>
    </div>
    <div class="flex-row flex-row-item">
      <div class="input-column">
        <label class="required font-bold !text-secondary">{{ "Price" | translate }}</label>
        <div class="input-group with-select">
          <input type="number" min="0" formControlName="price" />
          <span matSuffix class="text-secondary">{{currency}}</span>
        </div>
      </div>
    </div>
    <div class="flex-row flex-row-item">
      <div class="input-column">
        <label class="required font-bold !text-secondary">{{ "sellProductDetailPage.Quantity" | translate }}</label>
        <div class="input-group with-select">
          <input type="number" min="0" formControlName="qty" />
          <span matSuffix class="text-secondary">{{unit}}</span>
        </div>
      </div>
    </div>
    <div class="flex-row flex-row-item">
      <div class="input-column">
        <label class="required font-bold !text-secondary">{{ "marketDemand.smsToCustomer" | translate }}</label>
        <textarea rows="5" class="custom-textarea" formControlName="message"></textarea>
      </div>
    </div>
  </div>
</form>

<div mat-dialog-actions class="mat-dialog-actions btn-action" align="right">
  <button form="offer-price-dialog-form" type="submit" mat-flat-button color="primary" class="btn-md" [disabled]="!form.valid">
    <mat-icon class="svg-path svg-stroke" svgIcon="icon-plan"></mat-icon>
    {{ "MakeOffer" | translate }}
  </button>
</div>