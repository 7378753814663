import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { User } from 'src/app/models/user';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Pipe({
    name: 'apiTranslation'
})
export class ApiTranslationPip implements PipeTransform {

    localization: string = this.localStorageService.get(LocalStorageEnum.lang);
    constructor(
        private localStorageService: LocalStorageService
    ) { }

    transform(item: any): string {
        let localeAttr = "";
        if (item) {
            switch (this.localization) {
                case "km":
                    localeAttr = "_km"
                    break;
                case "en":
                default:
                    localeAttr = "_en"
                    break;
            }

        }

        let name = _.get(item, `name${localeAttr}`, "");
        if (!name) name = item.name;
        return name;
    }
}
