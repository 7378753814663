import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PictureComponent } from './picture.component';
import { TokenPipeModule } from '../token-pipe/token-pipe.module';

@NgModule({
  declarations: [
    PictureComponent
  ],
  imports: [
    CommonModule,
    TokenPipeModule
  ],
  exports: [
    PictureComponent
  ]
})
export class PictureModule { }
