import { Pipe, PipeTransform } from '@angular/core';
import { AnnouncementEnum } from 'src/app/models/enums/announcement.enum';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(status: string): string {

    let statusString: string = '';
    switch (status) {
      case AnnouncementEnum.PENDING:
        statusString = 'pending';
        break;
      case AnnouncementEnum.FOUND:
        statusString = 'found';
        break;
      default:
        statusString = statusString;
        break;
    }

    return statusString;
  }

}
