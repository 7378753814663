<button mat-icon-button [matMenuTriggerFor]="menu" (menuOpened)="menuOpened()" (menuClosed)="menuClosed()"
  [ngStyle]="isMenuOpen ? { 'background-color': '#ffffff2b' } : {}">
  <!-- <span class="material-icons-round" [matBadge]="dataSource.unreadCount > 0 ? dataSource.unreadCount : ''" matBadgeSize="small" matBadgeColor="warn"
        matBadgePosition="after">
        notifications
  </span> -->
  <mat-icon svgIcon="icon-notification" [matBadge]="badgeCount" matBadgeSize="small" matBadgeColor="warn"
    [matBadgeHidden]="badgeCount == '0'"></mat-icon>
</button>

<mat-menu #menu="matMenu" class="notification-menu" xPosition="before">
  <div class="polygon-img"><img src="assets/imgs/polygon.svg" /></div>
  <div class="notification-box-container" infiniteScroll [infiniteScrollDistance]="scrollDistance"
    [infiniteScrollThrottle]="throttle" [scrollWindow]="false" (scrolled)="onScroll()">
    <mat-card-header class="notification-title">
      <mat-card-title data="i18n" translate>Notification</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <div class="notification-list">
      <app-notification-item (click)="gotoPage(notification)" [title]="getNotificationTitle(notification)"
        [caption]="notification?.message"
        [date]="notification?.created_at | amLocale: translateService.currentLang | amTimeAgo"
        [seen]="notification?.seen"
        [imageUrl]="notification?.photo_url | token: ((notification?.type == notificationTypes.REPORT_CONTENT || notification?.type == notificationTypes.VIOLATED_CONTENT) ? 'assets/imgs/logo.svg' : '')"
        *ngFor="let notification of notifications"></app-notification-item>
      <mat-spinner [diameter]="40" *ngIf="isLoading"></mat-spinner>
    </div>
    <app-empty class="empty-box" *ngIf="!notifications?.length && !isLoading" image="assets/imgs/notification.svg"
      [title]="'MessagePage.NoNotification' | translate"
      [shortDescription]="'MessagePage.NotificationLater' | translate">
    </app-empty>
  </div>
</mat-menu>