import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportService } from 'src/app/services/report.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

export enum ReportEnum {
  BUY_LISTING = "BUY_LISTING",
  POST = "POST",
  PRODUCT = "PRODUCT",
  SHOP = "SHOP"
}
@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent implements OnInit {

  form: FormGroup;
  reportTypes: { _id: string; name_en: string; name_km: string; }[];

  imageUrls: any;
  imageFiles: File;
  maxUploadFiles: number = 10;

  constructor(
    public dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string, report_item: ReportEnum },
    private fb: FormBuilder,
    private reportService: ReportService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    if (!this.data) return;
    this.getAllReportType();
  }

  initFormGroup() {
    this.form = this.fb.group({
      reportTypes: [null, Validators.required],
      reason: ['']
    });
  }

  async getAllReportType() {
    const res = await this.reportService.getAllReportType().toPromise();
    this.reportTypes = res;
  }

  selectChange = (event: any) => {
    this.form.controls.reportTypes.setValue(event.data);
  }

  onFilePicked(e) {
    const files = e.target.files;
    if (files[0] && !files[0].type.match('image.*')) {
      this.snackbarService.onShowSnackbar(
        'ValidationErr.ChooseImageTypeOnly',
        false
      );
      return;
    }

    if (files.length > 0) {
      let imageName = files[0].name;
      if (imageName.lastIndexOf('.') <= 0) return;

      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => {
        this.imageUrls = fr.result;
        this.imageFiles = files[0];
      });
    }
  }

  findMaxLoop(newFileLength, currentImageUrlsLength, maxLength): number {
    let remainFileToAdd = maxLength - currentImageUrlsLength;
    return newFileLength > remainFileToAdd
      ? newFileLength - (newFileLength - remainFileToAdd)
      : newFileLength;
  }

  removeFile() {
    this.imageUrls = null;
    this.imageFiles = null;
  }

  isType(id: string) {
    return this.reportTypes.find(re => re._id == id);
  }

  async onSubmit() {
    if (!this.form.valid) return;

    const formValue = this.form.value;
    let report_type = [];

    if (formValue.reportTypes?.length > 0) {
      for (let [i, item] of formValue.reportTypes.entries()) {
        report_type['report_type[' + i + ']'] = this.isType(item)?._id;
      }
    }

    let data: any = {
      reason: formValue.reason,
      file: this.imageFiles,
      ...report_type,
    };
    switch (this.data?.report_item) {
      case ReportEnum.BUY_LISTING:
        data.buy_listing = this.data.id;
        break;
      case ReportEnum.POST:
        data.post = this.data.id;
        break;
      case ReportEnum.PRODUCT:
        data.product = this.data.id;
        break;
      case ReportEnum.SHOP:
        data.shop = this.data.id;
        break;
      default:
        break;
    }
    await this.postReportContent(data);
  }

  async postReportContent(data: any) {
    try {
      const res = await this.reportService.postReportContent(data).toPromise();
      if (res) {
        this.dialogRef.close(res);
      }
    } catch (error) {
      console.error(error?.message);
    }
  }

}
