<div class="side-nav">
  <div class="containers">
    <mat-action-list class="sl-list">
      <h3 class="mat-h3" translate>SideLeft.Content</h3>
      <button mat-list-item class="btn-list-item" routerLink="/home" routerLinkActive="active">
        <mat-icon class="svg-path svg-fill" svgIcon="icon-list-all"></mat-icon>
        <p class="title">{{ "SideLeft.NewsFeed" | translate }}</p>
      </button>
      <button
        mat-list-item
        class="btn-list-item"
        routerLink="/sell-product"
        routerLinkActive="active"
      >
        <mat-icon svgIcon="icon-sell"></mat-icon>
        <p class="title">{{ "SideLeft.Goods" | translate }}</p>
      </button>
      <button mat-list-item class="btn-list-item" routerLink="/shop" routerLinkActive="active">
        <mat-icon svgIcon="icon-buy"></mat-icon>
        <p class="title">{{ "SideLeft.Shop" | translate }}</p>
      </button>
      <button
        mat-list-item
        class="btn-list-item"
        routerLink="/announcement"
        routerLinkActive="active"
      >
        <div class="item">
          <div class="announcement">
            <mat-icon svgIcon="icon-shops"></mat-icon>
            {{ "SideLeft.MyProductAnnouncement" | translate }}
          </div>
          <!-- TODO: waiting api -->
          <!-- <div class="cicle">
          <div class="number">1</div>
        </div> -->
        </div>
      </button>
      <button mat-list-item class="btn-list-item" routerLink="/message" routerLinkActive="active">
        <mat-icon svgIcon="icon-chat"></mat-icon>
        <p class="title">
          {{ "SideLeft.Message" | translate }}
        </p>
        <span class="badge" *ngIf="unreadMsgCount > 0">{{ unreadMsgCount }}</span>
      </button>
      <button mat-list-item class="btn-list-item" routerLink="/my-order" routerLinkActive="active">
        <mat-icon class="svg-path svg-stroke" svgIcon="icon-order"></mat-icon>
        <p class="title">
          {{ "SideLeft.MyOrder" | translate }}
        </p>
      </button>
      <h3 class="mat-h3" translate>SideLeft.Others</h3>
      <button
        mat-list-item
        class="btn-list-item"
        routerLink="/kas-data-entry"
        routerLinkActive="active"
      >
        <mat-icon class="svg-path svg-fill" svgIcon="icon-list-all"></mat-icon>
        <p class="title">{{ "SideLeft.KasDataEntry" | translate }}</p>
      </button>
      <button
        mat-list-item
        class="btn-list-item"
        routerLink="/weather-forcast"
        routerLinkActive="active"
      >
        <mat-icon class="svg-path svg-fill" svgIcon="icon-weather"></mat-icon>
        <p class="title">
          {{ "SideLeft.Weather" | translate }}
        </p>
      </button>
    </mat-action-list>
    <app-switch-mode></app-switch-mode>
  </div>
</div>
