import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserPortalService } from 'src/app/services/user-portal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-confirm-password-dialog',
  templateUrl: './confirm-password-dialog.component.html',
  styleUrls: ['./confirm-password-dialog.component.scss'],
})
export class ConfirmPasswordDialogComponent implements OnInit {
  passwordForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
  })
  constructor(
    public dialogRef: MatDialogRef<ConfirmPasswordDialogComponent>,
    private userPortalService: UserPortalService,
    private router: Router
    ) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onDeleteClick(_event: Event): Promise<void> {
    if (!this.passwordForm.value.password || this.passwordForm.errors) return;
    try {
      const res = await this.userPortalService.deactivateAccount(this.passwordForm.value.password).toPromise();
      if (res) {
        this.dialogRef.close();
        this.router.navigate(['/login']);
      }
    } catch (err) {
      // This error message always return undefined
      console.error(err?.message);
    }
  }
}
