import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { Profile } from 'src/app/models/profile';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserPortalService } from 'src/app/services/user-portal.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {
  userId: string = '';
  isAuth: Boolean;
  profile: Profile;
  token: string = '';
  routesInStoreMode = ['/store-info', '/my-store-product', '/market-demand', '/my-store-product/products'];
  isLoginWithPassword: boolean = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userPortalService: UserPortalService,
    private localStorageService: LocalStorageService,
  ) {
    this.isLoginWithPassword = this.localStorageService.get(LocalStorageEnum.is_login_with_password) === 'true';

    this.authService.authChange$.subscribe((isAuth) => {
      this.isAuth = isAuth;
      this.userId = localStorageService.get(LocalStorageEnum.user_id);
      if (this.isAuth) {
        this.userPortalService.getProfile().subscribe((data) => {
          this.profile = data;
        });
      }
    });
  }

  ngOnInit(): void {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
  }

  linkUserAndStoreMode() {
    if (this.routesInStoreMode.findIndex(element => element === this.router.url) != -1) {
      this.router.navigateByUrl('/home');
    } else {
      this.router.navigateByUrl('/my-store-product');
    }
  }
}
