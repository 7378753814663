import { Product } from './../../../../models/product';
import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { slideAnimation } from './slide.animation';
import { fadeIn, fadeOut } from '../post-product-list/button.animation';
import { RequestQuoteProductDialogComponent } from 'src/app/shares/request-quote-product-dialog/request-quote-product-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shares/confirm-dialog/confirm-dialog.component';
import { CreateProductDialogComponent } from 'src/app/shares/create-product-dialog/create-product-dialog.component';
import { ProductService } from 'src/app/services/product.service';
import { Chat } from 'src/app/models/chat';
import { CreateProductType } from 'src/app/models/enums/create-product-type.enum';
import { ReportDialogComponent, ReportEnum } from 'src/app/shares/dialogs/components/report-dialog/report-dialog.component';
import { DoneDialogComponent } from 'src/app/shares/dialogs/components/done-dialog/done-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sell-product-detail-dialogue',
  templateUrl: './sell-product-detail-dialogue.component.html',
  styleUrls: ['./sell-product-detail-dialogue.component.scss'],
  animations: [slideAnimation, fadeIn, fadeOut],
})
export class SellProductDetailDialogueComponent implements OnInit {
  product: Product;
  token: string = '';
  is_favorite?: boolean;
  currentIndex: number = 0;
  chats: Chat[] = [];
  isLoading: boolean;
  param: any = {
    per_page: 10,
    page_number: 1,
  };
  throttle = 100;
  scrollDistance = 1;
  constructor(
    private dialog: MatDialog,
    private _favoriteService: FavoriteService,
    private localStorageService: LocalStorageService,
    public dialogRef: MatDialogRef<SellProductDetailDialogueComponent>,
    private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
    this.product = this.data.product;
    this.getProductDetail(this.product._id);
    this.getProductRequest(this.param);
  }

  getProductDetail(productID: string): void {
    this.productService.getProductDetail(productID).subscribe((res) => {
      this.product = res?.data;
    });
  }

  unsaved(product: Product) {
    this._favoriteService.unsaveProduct(product?._id).subscribe((res: any) => {
      product.is_favorite = !product?.is_favorite;
      this.product.favorites_count = res?.favorite_count;
    });
  }

  onSave(product: Product) {
    this._favoriteService
      .saveProduct({ product_id: product?._id })
      .subscribe((res: any) => {
        product.is_favorite = !product?.is_favorite;
        this.product.favorites_count = res?.favorite_count;
      });
  }

  setCurrentSlideIndex(index) {
    this.currentIndex = index;
  }

  isCurrentSlideIndex(index) {
    return this.currentIndex === index;
  }

  prevSlide() {
    this.currentIndex =
      this.currentIndex < this.product.photo_urls.length - 1
        ? ++this.currentIndex
        : 0;
  }

  nextSlide() {
    this.currentIndex =
      this.currentIndex > 0
        ? --this.currentIndex
        : this.product.photo_urls.length - 1;
  }

  openRequestQuoteDialog(product: Product) {
    this.dialogRef.close();
    const quoteDialogRef = this.dialog.open(
      RequestQuoteProductDialogComponent,
      {
        width: '400px',
        data: {
          product: product,
          enableBackButton: true,
        },
      }
    );
    quoteDialogRef.afterClosed().subscribe((res: any) => {
      if (res?.requested) {
        this.dialogRef.close({ requested: true });
      }
    });
  }

  onDelete() {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'small-modal',
      data: {
        title: 'TextDelete',
        icon: 'assets/imgs/confirm-img.svg',
        message: 'ConfirmMessage.Delete',
        button: 'Delete',
      },
    });
    ref.afterClosed().subscribe((res) => {
      if (res == 'confirm') {
        this.dialogRef.close({ deleted: true });
      }
    });
  }

  onEdit(product: Product) {
    const ref = this.dialog.open(CreateProductDialogComponent, {
      disableClose: true,
      panelClass: 'large-modal',
      maxHeight: '90vh',
      maxWidth: '90vw',
      data: {
        profile: this.data?.profile,
        token: this.data?.token,
        product,
        shopID: this.data?.shopID,
        type: CreateProductType.PRODUCT,
      },
    });
    ref.afterClosed().subscribe((res) => {
      if (res) {
        this.product = res;
      }
    });
  }

  mapCategoryName(categories: any[]) {
    return categories.map((category: any) => {
      return category.name
    });
  }

  getProductRequest(data) {
    this.isLoading = true;
    this.productService.getProductRequest(this.product._id, data).subscribe(
      (res: any) => {
        this.chats.push(...res.data);
        this.isLoading = false;
      },
      (err) => {
        console.error(err?.message);
        this.isLoading = false;
      }
    );
  }

  onScroll(): void {
    this.param.page_number++;
    this.getProductRequest(this.param);
  }

  openReportDialog() {
    const dialogRef = this.dialog.open(ReportDialogComponent, {
      width: '500px',
      autoFocus: false,
      disableClose: true,
      data: {
        id: this.product._id,
        report_item: ReportEnum.PRODUCT
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.openDialogSuccess();
      }
    });
  }

  openDialogSuccess() {
    const dialogRef = this.dialog.open(DoneDialogComponent, {
      width: '400px',
      data: {
        svgIcon: "report",
        title: "Report.successReport",
        message: "Report.successReportText",
        button: "Status.accept"
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'accept') {
        this.dialog.closeAll();
        this.reloadComponent();
      }
    });
  }

  reloadComponent() {
    // When true, navigates without pushing a new state into history.
    // Navigate silently to /view
    this.router.navigate(['/view'], { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
    });
  }

}
