import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  message: string;
  icon: string;
  button: string;
  paramValue?: string;
  isBtnCancel?: boolean;
  dialogTemplate?: DialogTemplate
}

export enum DialogTemplate {
  DEFAULT = "DEFAULT",
  NO_HEADER = "NO_HEADER"
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  readonly DialogTemplate = DialogTemplate;

  dialogData: DialogData;
  title: string;
  message: string;
  icon: string;
  button: string;
  isBtnCancel: boolean = true;
  dialogTemplate: DialogTemplate = DialogTemplate.DEFAULT;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.message = this.data.message;
    this.title = this.data.title;
    this.icon = this.data.icon ?? 'assets/imgs/confirm-img.svg';
    this.isBtnCancel = this.data.isBtnCancel ?? true;
    this.button = this.data.button.toLowerCase();
    this.param.value = this.data.paramValue;
    if (this.data.dialogTemplate) this.dialogTemplate = this.data.dialogTemplate;
  }

  private onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  param = {
    value: ''
  };
}
