/**
 * This enum for CreateProductDialog
 * Currently it just has only buy_listing and product(own store)
 * - buy_listing (in announcement and in user mode or when it provides buy or buyListing info to dialog)
 * - product (most of the time is in store mode or when it provides product or shop info to dialog)
 * **Note: when open CreateProductDialog need to provide this type,
 * Because in CreateProductDialog checks these two conditions
 */
export enum CreateProductType {
    BUY_LISTING = 'BUY_LISTING',
    PRODUCT = 'PRODUCT'
}