<div class="menu-box">
  <div
    class="menu-item"
    matRipple
    routerLink="/my-store-product"
    routerLinkActive="active"
  >
    <mat-icon svgIcon="icon-cart-white"></mat-icon>
    <h5 class="title">{{ "StoreModeMenu.MyProduct" | translate }}</h5>
  </div>
  <div
    routerLink="market-demand"
    class="menu-item"
    matRipple
    routerLinkActive="active"
  >
    <mat-icon svgIcon="icon-cart-add-white"></mat-icon>
    <h5 class="title">{{ "StoreModeMenu.MarketDemand" | translate }}</h5>
  </div>
  <div
    routerLink="order-management"
    class="menu-item"
    matRipple
    routerLinkActive="active"
  >
    <mat-icon svgIcon="icon-add-box"></mat-icon>
    <h5 class="title">{{ "StoreModeMenu.PurchaseOrder" | translate }}</h5>
  </div>
  <div
    class="menu-item"
    matRipple
    routerLinkActive="active"
    routerLink="store-message"
  >
    <mat-icon svgIcon="icon-chat-white"></mat-icon>
    <h5 class="title">{{ "StoreModeMenu.Message" | translate }}</h5>
    <p class="badge" *ngIf="unreadMsgCount > 0">{{ unreadMsgCount }}</p>
  </div>
  <div
    class="menu-item"
    matRipple
    routerLinkActive="active"
    routerLink="payment-account"
  >
    <mat-icon svgIcon="icon-wallet"></mat-icon>
    <h5 class="title">{{ "StoreModeMenu.Payment" | translate }}</h5>
  </div>
</div>
