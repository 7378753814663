import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestParam } from '../models/request-param';
import { User } from '../models/user';
import { BaseCrudService } from './base-crud.service';

@Injectable({
  providedIn: 'root',
})
export class ForgetPasswordService extends BaseCrudService<User> {
  constructor(injector: Injector) {
    super(injector);
    this.path = environment.auth_url;
  }
  SetPasswordEmail(data: RequestParam) {
    return this.requestService.postJSON<User>(
      this.path + '/reset-password-email',
      data
    );
  }

  verifyEmailForgetPassword(data: RequestParam) {
    return this.requestService.postJSON(
      this.path + '/verify-forgot-password-token',
      data
    );
  }

  resendEmailVerifyForgetPassword(data: RequestParam) {
    return this.requestService.postJSON(
      this.path + '/resend-forgot-password-token',
      data
    );
  }

  changeNewPassword(data: RequestParam) {
    return this.requestService.postJSON(
      this.path + '/reset-password-email-with-token',
      data
    );
  }
}
