<!-- <div class="profile-online online">
  <div class="profile-image">
    <div class="images">
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfF_ZloBA_N-x8_UWTpSojngCvMdTjL7lUgw&usqp=CAU"
        alt=""
      />
    </div>
    <div class="profile-name">
      <div class="name-with-name">
        <h3 class="mat-h3">រតនា</h3>
        <div class="caption-sm">|</div>
        <span class="caption-sm"> 12 នាទីមុន</span>
      </div>
      <span class="caption-sm">បានស្លាកឈ្មោះអ្នក</span>
    </div>
  </div>
</div> -->
<div class="notification-item" matRipple>
  <div class="img-box">
    <div class="img" [style.background-image]="'url(' + imageUrl + ')'"></div>
  </div>
  <div class="text-box">
    <div class="flex-space-between">
      <h3 class="title">{{ title }}</h3>
      <div *ngIf="!seen" class="unseen"></div>
    </div>
    <div class="flex-space-between">
      <p class="caption" [ngStyle]="{color: seen ? '#979797' : 'rgba(0,0,0,' + 0.8 +')'}">{{ caption }}</p>
      <p class="time-ago">{{ date }}</p>
    </div>
  </div>
</div>