import { LoginData } from './../models/datas/login.data';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageEnum } from '../models/enums/local-storage.enum';
import { NavigationStart, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FullNamePipe } from '../shares/name/pipes/full-name.pipe';
import { TranslateService } from '@ngx-translate/core';
import { LanguagePreferenceService } from './language-preference.service';
import { SnackbarService } from './snackbar.service';
import { ChatService } from './chat.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authChange$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.isAuth
  );
  authStatus: boolean = this.isAuth;
  constructor(
    private router: Router,
    private requestService: RequestService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private languagepreferenceService: LanguagePreferenceService,
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
    private chatService: ChatService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.authStatus != this.isAuth) {
          this.markStatusChange();
        }
      }
    });
  }

  login(data: { identifier: string; password: string }): Observable<LoginData> {
    const data1 = {
      identifier: data.identifier,
      password: data.password,
    };
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/authenticate', {
        data: data1,
      })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  loginWithGoogle(
    data: { token: string },
    profile_image?: string
  ): Observable<LoginData> {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/register-google', { data })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  loginWithFacebook(
    data: { token: string },
    profile_image?: string
  ): Observable<LoginData> {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/register-facebook', {
        data,
      })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  loginWithCamDigiKey(data: { token: string }) {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/authenticate-camdigikey', {
        data,
      })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  loginWithApple(data: { token: string }) {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/authenticate-apple', {
        data,
      })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  registerWithApple(data: {
    first_name: string;
    last_name: string;
    token: string;
  }) {
    return this.requestService
      .postJSON<LoginData>(environment.auth_url + '/register-apple', { data })
      .pipe(
        map((res) => {
          this.onLoginSuccess(res);
          return res;
        })
      );
  }

  fullNamePipe = new FullNamePipe();

  onLoginSuccess(res) {
    const token = res.token;
    const userId = res.user._id;
    const fullName = this.fullNamePipe.transform(res.user, '');
    const photoUrl = res.user.photo_url;
    this.localStorageService.set(LocalStorageEnum.token, token);
    this.localStorageService.set(LocalStorageEnum.refresh_token, res.refresh_token);
    this.localStorageService.set(LocalStorageEnum.user_id, userId);
    this.localStorageService.set(LocalStorageEnum.user_fullname, fullName);
    this.localStorageService.set(LocalStorageEnum.user_photo_url, photoUrl);
    this.localStorageService.set(LocalStorageEnum.is_login_with_password, (res?.user?.is_login_with_password));

    this.chatService.loginSocket();
    this.authChange$.next(true);
    // update default language based on user language preference
    this.checkLanguagePreference();
  }

  async checkLanguagePreference() {
    try {
      let res = await this.languagepreferenceService
        .getPreference()
        .toPromise();
      let lang = res?.data?.preference?.language;
      if (!lang) lang = 'km';
      this.translateService.use(lang);
      this.translateService.setDefaultLang(lang);
    } catch (err) {
      console.error(err);
    }
  }

  logout() {
    this.localStorageService.delete(LocalStorageEnum.token);
    this.localStorageService.delete(LocalStorageEnum.refresh_token);
    this.localStorageService.delete(LocalStorageEnum.user_id);
    this.localStorageService.delete(LocalStorageEnum.user_fullname);
    this.localStorageService.delete(LocalStorageEnum.user_photo_url);
    this.localStorageService.delete(LocalStorageEnum.shop_id);
    this.localStorageService.delete(LocalStorageEnum.is_login_with_password);
    this.chatService.logoutSocket();
    this.logoutToken();
    this.markStatusChange();
  }

  logoutToken() {
    return this.requestService.deleteJSON(environment.auth_url + '/logout-token');
  }

  changeLanguage() {
    this.translateService.currentLang == 'km'
      ? this.translateService.use('en')
      : this.translateService.use('km');
  }

  private markStatusChange() {
    this.authChange$.next(this.isAuth);
    this.authStatus = this.isAuth;
  }

  get isAuth(): boolean {
    return this.localStorageService.get(LocalStorageEnum.token) ? true : false;
  }

  getAuth(): Observable<boolean> {
    return this.authChange$.asObservable();
  }

}
